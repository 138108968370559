<template>
  <div class="main-body">
      <div class="binding-box">
          <div class="binding-mark" v-if="!manual_state">
              <van-icon class="binding-mark-img" name="warning" />
<!--              <div v-if="binding_info.length > 0" class="binding-mark-msg">系统识别到当前客户与系统中的 <span>{{ binding_info[0].user_name }}</span> (客户序号<span>{{ binding_info[0].id }}</span>) 联系方式相匹配。是否将当前客户与系统中的客户进行绑定？</div>-->
              <div v-if="binding_info.length > 0" class="binding-mark-msg">系统识别到当前客户与CRM系统中的下列客户联系方式相匹配，请选择一个列表中的CRM客户与当前客户进行绑定。</div>
              <div v-else class="binding-mark-msg">系统未识别到联系方式匹配的客户，请手动搜索并绑定客户。</div>
          </div>
          <div class="binding-search" v-if="!customerListState">
              <van-dropdown-menu class="binding-search-left">
                  <van-dropdown-item v-model="search_data.condition" :options="search_condition_list" :disabled="binding_load"/>
              </van-dropdown-menu>
              <van-search
                class="binding-search-right"
                v-model="search_data.value"
                left-icon=""
                autocomplete="off"
                @search="searchCustomer"
                :removeForm="true"
                :disabled="binding_load"
                :placeholder="'输入' + search_condition_list.find(i=>i.value === search_data.condition).text "
              >
                  <template #right-icon>
                      <van-icon @click="searchCustomer" name="search" />
                  </template>
              </van-search>
          </div>
          <div class="binding-list">
              <div
                class="binding-list-item"
                :class="{'binding-item-active':item.id === customerSelId}"
                v-for="(item,index) in customerListState ? binding_info : customerList"
                :key="index"
                @click="customerSel(item)"
              >
                  <div class="binding-item-top">
                      <div class="binding-item-top-name">{{ item.user_name }}</div>
                      <div class="binding-item-top-id">ID：{{ item.id }}</div>
                  </div>
                  <div class="binding-item-info">
                      <div class="binding-item-info-name">跟单顾问</div>
                      <div class="binding-item-info-msg">{{ item.follow_counsel_name || '-' }}</div>
                  </div>
                  <div class="binding-item-info">
                      <div class="binding-item-info-name">运营客服</div>
                      <div class="binding-item-info-msg">{{ item.official_service_name || '-' }}</div>
                  </div>
                  <div class="binding-item-info">
                      <div class="binding-item-info-name">意向国家</div>
                      <div class="binding-item-info-msg">{{ item.intentional_state || '-' }}</div>
                  </div>
                  <div class="binding-item-info">
                      <div class="binding-item-info-name">来源渠道</div>
                      <div class="binding-item-info-msg">{{ (item.source_name === '自定义来源' || item.source_name === '老客户介绍') ? item.source_name : '-' }}</div>
                  </div>
                  <div class="binding-item-info">
                      <div class="binding-item-info-name">客户等级</div>
                      <div class="binding-item-info-msg">{{ levelName(item.customer_grade) }}</div>
                  </div>

              </div>
              <div class="empty-box" v-if="(customerListState ? binding_info : customerList).length === 0 && !this.search_load">
                  <img class="empty-img" src="@/assets/empty.png" />
                  <div class="empty-msg">暂无数据</div>
              </div>
          </div>

      </div>
      <div class="footer-box">
          <van-button v-if="!manual_state && binding_info.length > 0" class="footer-btn" type="default" @click="changeManual()">手动绑定</van-button>
          <van-button class="footer-btn footer-btn2" type="default" :loading="binding_load" loading-text="绑定中..." @click="bindingCustomer">确认绑定</van-button>
      </div>
  </div>
</template>

<script>


import {
    getBindList,
    postBindCustomer,

} from "@/api/binging";
import {
    Notify,
    Dialog, Toast,
} from "vant";

export default {
    data() {
        return {
            binding_info: [],
            customerSelId:'',
            customerSelName:'',
            customerList: [],
            search_condition_list: [
                { text: '客户序号', value: 0 },
                { text: '客户名称', value: 1 },
                { text: '手机号', value: 2 }
            ],

            search_data: {
                condition: 0,
                value: '',
            },
            search_load: false,
            manual_state: false, // 手动绑定标识
            binding_load: false,
            userId: '',
        }
    },
    created() {
        this.userId = this.$route.query.userId
        this.getAllList(true)
    },
    mounted() {
    },

    computed: {
        // 自动/手动绑定状态
        customerListState() {
            return this.binding_info.length > 0 && !this.manual_state
        },

    },
    methods: {
        changeManual(){
            this.manual_state = true
            this.customerSelId = ''
            this.customerSelName = ''
        },
        // 获取绑定列表
        getAllList(type){
            let para = {
                qw_customer_id: type ? this.userId : '',
                type: this.search_data.condition,
                select_text: this.search_data.value,
            }
            this.customerList = []
            this.search_load = true
            this.$showLoad(true)

            getBindList(para).then(res=>{
                if (res.code === 200){
                    this.binding_info = res.data.automaticBindCustomerList
                    // this.binding_info = []
                    this.customerList = res.data.adviserCustomerList
                    // this.customerList = []
                    this.customerSelId = ''
                    this.customerSelName = ''
                    this.$showLoad()
                    this.search_load = false
                }else {
                    this.$showLoad()
                    this.search_load = false
                    Notify({type: 'danger', message: res.message})
                }
            }).catch(() => {
                this.$showLoad()
                this.search_load = false
                Notify({type: 'warning', message: '服务器连接失败'})
            })

        },


        // 客户等级展示
        levelName(row) {
            let levelArr = ['', 'A', 'B', 'C', 'D', 'E', '无效', '未分级']
            return levelArr[row]
        },

        // 客户绑定
        bindingCustomer(){
            let para = {
                customer_id: '',
                qw_customer_id: this.userId,
            }
            // if (!this.customerListState) {
            //     if (!this.customerSelId) {
            //         Notify({type: 'warning', message: '请选择客户'})
            //         return
            //     }
            //     para.customer_id = this.customerSelId
            //
            // } else {
            //     para.customer_id = this.binding_info[0].id
            //     this.customerSelName = this.binding_info[0].user_name
            // }
            if (!this.customerSelId) {
                Notify({type: 'warning', message: '请选择客户'})
                return
            }
            para.customer_id = this.customerSelId

            Dialog.confirm({
                title: '温馨提示',
                message: '确认将当前微信客户与CRM系统中的' + this.customerSelName + '（客户序号' + para.customer_id + '）绑定？',
                confirmButtonColor: '#419EFF',
            })
              .then(() => {
                  this.binding_load = true
                  postBindCustomer(para).then(data => {
                      if (data.code === 200) {
                          Toast.success('绑定成功，即将跳转详情页');
                          this.binding_load = false
                          setTimeout(()=>{
                              this.$router.replace(
                                {
                                    path: '/customerInfo',
                                    query: {
                                        customer_id: para.customer_id,
                                    }
                                }
                              )
                          },2000)

                      } else {
                          this.binding_load = false
                          Notify({type: 'danger', message: data.message})
                      }
                  }).catch(() => {
                      this.binding_load = false
                      Notify({type: 'warning', message: '服务器连接失败'})
                  })
              })
              .catch(() => {

              });

        },

        // 选中客户
        customerSel(data){
            this.customerSelId = data.id
            this.customerSelName = data.user_name
            // if (!this.customerListState && !this.binding_load){
            //
            // }
        },

        // 搜索
        searchCustomer(){
            if (!this.binding_load){
                this.getAllList()
            }

        },

    },


}
</script>

<style scoped>
.main-body {
    background: #FFFFFF;
    height: 100vh;
    position: relative;
}
.footer-box{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0px -2px 2px 0px rgba(228,228,228,0.5);
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

.footer-btn{
    width: calc((100% - 15px) / 2);
    box-sizing: border-box;
    height: 50px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #E5E5E5;
    color: #333333;
}
.footer-btn.footer-btn2{
    border: none;
    background: #409EFF;
    color: #FFFFFF;
}
.binding-box{
    height: calc(100% - 90px);
    width: 100%;
    box-sizing: border-box;
    padding: 30px 10px 0;
    display: flex;
    flex-direction: column;
}

.binding-mark{
    display: flex;
    margin-bottom: 20px;
}

.binding-mark-img{
    font-size: 14px;
    color: #419EFF;
    margin-top: 5px;
}

.binding-mark-msg{
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #272727;
    line-height: 22px;
    word-wrap: break-word;
    word-break: break-all;
}
.binding-mark-msg>span{
    font-weight: 600;
}

.binding-search{
    width: 100%;
    height: 40px;
    background: #F5F5FC;
    border-radius: 43px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.binding-search-left{
    width: 70px;
    display: inline-block;
    height: 100%;
}
.binding-search-left >>> .van-dropdown-menu__bar{
    box-shadow: none;
    background: none;
    height: 100%;
}
.binding-search-left >>>.van-dropdown-menu__title{
    font-weight: 400;
    font-size: 14px;
    color: #5A6377;
    line-height: 18px;
    padding-left: 0;
}

.binding-search-left >>>.van-dropdown-item__option--active{
    color: #419EFF;
}
.binding-search-left >>>.van-dropdown-item__option--active .van-dropdown-item__icon{
    color: #419EFF;
}

>>>.binding-search-right{
    padding: 0;
    flex: 1;
    height: 100%!important;
}

.binding-search-right >>>.van-search__content{
    padding-left: 20px;
    background: #F5F5FC;
    height: 100%;

}

.binding-search-right >>> .van-cell{
    padding: 0;
}

.binding-search-right >>> .van-field__control{
    height: 40px;
}
.binding-list{
    flex: 1 1;
    padding: 0 0 30px;
    box-sizing: border-box;
    overflow-y: auto;
    /*margin-top: 20px;*/
    position: relative;
}

.binding-list-item{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(211,211,211,0.4);
    border-radius: 16px;
    border: 1px solid #F3F5F9;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 15px;
    margin-bottom: 15px;
}

.binding-list-item:last-child{
    margin-bottom: 0;
}
.binding-list-item.binding-item-active{
    box-shadow: 0px 2px 4px 0px rgba(236,236,236,0.34);
    border: 1px solid #409EFF;
}
.binding-item-top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    font-weight: 600;
}
.binding-item-top>*{
    flex-shrink: 0;
}
.binding-item-top-name{
    font-size: 16px;
    color: #272727;
    line-height: 22px;
    max-width: 70%;
    word-wrap: break-word;
    word-break: break-all;
}
.binding-item-top-id{
    font-size: 14px;
    color: #419EFF;
    line-height: 18px;
}
.binding-item-info{
    display: flex;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.binding-item-info:last-child{
    margin-bottom: 0;
}
.binding-item-info>*{
    flex-shrink: 0;
}
.binding-item-info-name{
    color: #6F7379;
    margin-right: 30px;
}
.binding-item-info-msg{
    color: #272727;
    word-wrap: break-word;
    word-break: break-all;
    flex: 1;
}

.empty-box{
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    /*bottom: 0;*/
    margin: auto;
    width: fit-content;
    height: fit-content;
}
.empty-img{
    width: 161px;
    height: 107px;
}
.empty-msg{
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    line-height: 22px;
    text-align: center;
}

</style>
