<template>
    <div class="main-body" id="mainBody">
        <!--       <van-loading class="body-load" v-if="info_load"/>-->
        <div class="main-body-content center" v-if="!customer_id">
            <div class="img-box">
                <img src="../assets/empty.svg" alt="empty">
            </div>
            <div class="empty-box">
                请前往客户详情页面进行客户绑定，绑定 <br />
                成功后查看客户跟单记录
            </div>
        </div>
        <div class="main-body-content" v-if="isEdit && customer_id">
            <div class="title">
                <div class="title-text">{{customer_name}}</div>
                <div class="title-number">ID：{{customer_id}}</div>
            </div>

            <div class="info-box" v-for="item in trackData" :key="item.id" v-if="trackData.length > 0">
                <div class="info-box-title" v-if="item['follow_content'] && item['follow_content'] !=='-'">{{item['follow_content']}}</div>
                <div class="info-box-item">
                    <div class="info-box-item-title">跟单时间</div>
                    <div class="info-box-item-content">{{item['follow_time']}}</div>
                </div>
                <div class="info-box-item">
                    <div class="info-box-item-title">回访方式</div>
                    <div class="info-box-item-content">{{returnType(item['follow_type'])}}</div>
                </div>
                <div class="info-box-item">
                    <div class="info-box-item-title">回访结果</div>
                    <div class="info-box-item-content">{{item['follow_result'] === 0 ? '失败' : '成功'}}</div>
                </div>
                <div class="info-box-item">
                    <div class="info-box-item-title">添加人员</div>
                    <div class="info-box-item-content">{{item.add_user}}</div>
                </div>

                <div class="record-button">
                    <div v-if="!item.path" class="operate-btn"></div>
                    <van-button v-if="item.path" class="operate-btn sound-btn" type="default" @click="recordPlay(item)">回访录音</van-button>
                    <van-button class="operate-btn edit-btn" type="default" :disabled="!item.record_allow" @click="editClick(item)">修改</van-button>
                </div>

            </div>
            <div class="center" v-else>
              <div class="img-box">
              <img src="../assets/empty.png" alt="">
              </div>
              <div class="empty-box">暂无数据</div>
            </div>
        </div>
        <!--     添加 修改-->
        <div v-if="!isEdit && customer_id" id="info-box-out" class="info-box-out">
            <div class="info-box-edit-title">
                <div class="info-box-edit-title-in">跟单记录</div>
            </div>
            <div class="info-box-edit-form">
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">跟单时间 <span>(必填)</span></div>
                    <div class="info-edit-form-time">
                        <div class="info-edit-form-follow_date">
                            <van-field
                                    class="followTimeStyle"
                                    readonly
                                    name="picker"
                                    v-model="customer_info.follow_date"
                                    placeholder="选择跟单日期"
                                    @click="company_build_show = true"
                            >
                                <template #left-icon>
                                    <van-icon name="notes-o" color="#C5C9D0" size="14px" />
                                </template>
                            </van-field>
                            <van-popup v-model:show="company_build_show" position="bottom">
                                <van-datetime-picker
                                        v-model="selectedFollowDate"
                                        type="date"
                                        :max-date="company_build_maxDate"
                                        @confirm="companyBuildConfirm"
                                        @cancel="company_build_show = false"
                                />
                            </van-popup>
                        </div>

                        <div class="info-edit-form-follow_time">
                            <van-field
                                    class="followTimeStyle"
                                    readonly
                                    name="picker"
                                    v-model="customer_info.followTime"
                                    placeholder="选择跟单时间"
                                    @click="openPicker"
                            >
                                <template #left-icon>
                                    <van-icon name="clock-o" color="#C5C9D0" size="14px" />
                                </template>
                            </van-field>
                            <van-popup v-model:show="followTime_show" position="bottom">
                                <van-datetime-picker
                                        v-model="customer_info.followTime"
                                        type="time"
                                        @confirm="followTimeConfirm"
                                        @cancel="followTime_show = false"
                                />
                            </van-popup>
                        </div>
                    </div>
                </div>
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">回访方式  <span>(必填)</span></div>
                    <div class="info-edit-form-input">
                        <van-field
                                readonly
                                name="picker"
                                v-model="customer_info.modalityView"
                                placeholder="选择回访方式"
                                @click="project_show = true"
                        />
                        <van-popup v-model:show="project_show" position="bottom">
                            <van-picker
                                    :default-index="findIndex(customer_info.modality,infomationData,1)"
                                    show-toolbar
                                    :columns="infomationData"
                                    @confirm="projectConfirm"
                                    @cancel="project_show = false"
                            />
                        </van-popup>
                    </div>
                </div>
                <div class="info-box-edit-star-tip">
                    跟单内容填写要求：跟单内容需要体现内容的价值，不允许填写无意义的动作而没有结果。
                    <br />
                    无效跟单样例：1、加微信。2、电话未接。3、未联系上
                </div>
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">跟单内容 <span>(必填)</span></div>
                    <div class="info-edit-form-sel info-edit-form-checkbox" style="margin-top: 0;">
                        <van-field
                                style="margin-top: 0;"
                                maxlength="300"
                                v-model="customer_info.remarks"
                                type="textarea"
                                placeholder="请写跟单记录。例如：客户张三，北京人，本科学历，教培公司企业主，两个孩子分别7岁和4岁，配偶是银行运营督导，家庭年收入80万以上，都支持移民，已了解移民3个月，可持续跟进，预计明天10点第二次联系。"
                        />
                    </div>
                </div>
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">跟单结果 <span>(必填)</span></div>
                    <div class="info-edit-form-sel">
                        <van-radio-group
                                v-model="customer_info.result"
                                direction="horizontal"
                                icon-size="13"
                                checked-color="#419EFF"
                        >
                            <van-radio :name="1">回访成功</van-radio>
                            <van-radio :name="0">回访失败</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">提醒时间</div>
                    <div class="info-edit-form-time">
                        <div class="info-edit-form-follow_date">
                            <van-field
                                    class="followTimeStyle"
                                    readonly
                                    name="picker"
                                    v-model="customer_info.remindDate"
                                    placeholder="选择提醒日期"
                                    @click="remindDate_show = true"
                            >
                                <template #left-icon>
                                    <van-icon name="notes-o" color="#C5C9D0" size="14px" />
                                </template>
                            </van-field>
                            <van-popup v-model:show="remindDate_show" position="bottom">
                                <van-datetime-picker
                                        v-model="selectedRemindDate"
                                        type="date"
                                        :min-date="company_build_minDate"
                                        @confirm="remindDateConfirm"
                                        @cancel="remindDate_show = false"
                                />
                            </van-popup>
                        </div>

                        <div class="info-edit-form-follow_time">
                            <van-field
                                    class="followTimeStyle"
                                    readonly
                                    name="picker"
                                    v-model="customer_info.remindTime"
                                    placeholder="选择提醒时间"
                                    @click="openRemindTimePicker"
                            >
                                <template #left-icon>
                                    <van-icon name="clock-o" color="#C5C9D0" size="14px" />
                                </template>
                            </van-field>
                            <van-popup v-model:show="remindTime_show" position="bottom">
                                <van-datetime-picker
                                        v-model="customer_info.remindTime"
                                        type="time"
                                        :min-date="followTime_minDate"
                                        @confirm="remindTimeConfirm"
                                        @cancel="remindTime_show = false"
                                />
                            </van-popup>
                        </div>
                    </div>
                </div>
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">备注</div>
                    <div class="info-edit-form-sel info-edit-form-checkbox" style="margin-top: 0;">
                        <van-field
                                style="margin-top: 0;"
                                maxlength="300"
                                v-model="customer_info.trackRemarks"
                                type="textarea"
                                placeholder="输入备注"
                        />
                    </div>
                </div>

            </div>
            <div class="info-box-edit-title">
                <div class="info-box-edit-title-in">重要事项提醒</div>
            </div>
            <div class="info-box-edit-form">
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">提醒时间</div>
                    <div class="info-edit-form-time">
                        <div class="info-edit-form-follow_date">
                            <van-field
                                    class="followTimeStyle"
                                    readonly
                                    name="picker"
                                    v-model="customer_info.importantRemindDate"
                                    placeholder="选择提醒日期"
                                    @click="importantRemindDate_show = true"
                            >
                                <template #left-icon>
                                    <van-icon name="notes-o" color="#C5C9D0" size="14px" />
                                </template>
                            </van-field>
                            <van-popup v-model:show="importantRemindDate_show" position="bottom">
                                <van-datetime-picker
                                        v-model="selectedImportantDate"
                                        type="date"
                                        :min-date="company_build_minDate"
                                        @confirm="importantRemindDateConfirm"
                                        @cancel="importantRemindDate_show = false"
                                />
                            </van-popup>
                        </div>

                        <div class="info-edit-form-follow_time">
                            <van-field
                                    class="followTimeStyle"
                                    readonly
                                    name="picker"
                                    v-model="customer_info.importantRemindTime"
                                    placeholder="选择提醒时间"
                                    @click="openImportantRemindTimePicker"
                            >
                                <template #left-icon>
                                    <van-icon name="clock-o" color="#C5C9D0" size="14px" />
                                </template>
                            </van-field>
                            <van-popup v-model:show="importantRemindTime_show" position="bottom">
                                <van-datetime-picker
                                        v-model="customer_info.importantRemindTime"
                                        type="time"
                                        :min-date="followTime_minDate"
                                        @confirm="importantRemindTimeConfirm"
                                        @cancel="importantRemindTime_show = false"
                                />
                            </van-popup>
                        </div>
                    </div>
                </div>
                <div class="info-box-edit-form-item">
                    <div class="info-edit-form-title">重要事项提醒</div>
                    <div class="info-edit-form-sel info-edit-form-checkbox" style="margin-top: 0;">
                        <van-field
                                style="margin-top: 0;"
                                maxlength="300"
                                v-model="customer_info.orderContentImportant"
                                type="textarea"
                                placeholder="输入重要事项提醒"
                        />
                    </div>
                </div>
            </div>

        </div>
        <!--      底部按钮-->
        <div v-if="!info_load && customer_id" class="footer-box">
            <!--        <img class="to-top" src="@/assets/to-top.svg" @click="toTop"/>-->
            <van-button v-if="!isEdit" class="footer-btn" type="default" @click="exitEdit()">取消</van-button>
            <van-button v-if="!isEdit" class="footer-btn footer-btn2" type="default" @click="saveEdit()">保存</van-button>
            <div v-if="isEdit" class="default-btn"></div>
            <van-button v-if="isEdit" class="footer-btn footer-btn2" type="default" @click="addDocumentation()">添加跟单</van-button>
        </div>




        <!-- S 录音弹出 -->
        <Audio
                ref="audio"
                v-if="recordVisible"
                :title="recordTitle"
                :src="src"
                :dialogVisible="recordVisible"
                @cloceAudio="stop">
        </Audio>
        <!-- E 录音弹出 -->
    </div>
</template>

<script>
import moment from "moment/moment";
import {Notify} from "vant";
import {addDocumentary, getDocumentary} from '@/api/binging'
import Audio from '../components/audio.vue'
export default {
    components: {
      Audio
    },
    data () {
        return {
            // S 录音
            recordVisible:false,
            recordTitle:'',
            src:'',
            // E 录音
            infomationData:[ //回访方式列表
                {value:0,text:'电话'},
                {value:1,text:'微信'},
                {value:2,text:'邮件'},
                {value:3,text:'QQ'},
                {value:4,text:'WhatsApp'},
            ],
            trackData:[],
            info_load: false,
            isEdit:true,
            project_show: false,
            company_build_show: false,
            followTime_show:false,
            company_build_maxDate: new Date(),
            company_build_minDate: new Date(),
            followTime_minDate: moment().toDate(),
            remindDate_show:false,
            remindTime_show:false,
            importantRemindDate_show:false,
            importantRemindTime_show:false,
            follow_order_id:'',//数据id
            customer_name:'白桂香（添加客服VX：ym把客户VX发给你）',
            customer_id:'',
            customer_info:{
                modalityView:'',
                modality:null,//跟单方式
                follow_date:'',//跟单日期
                followTime:'',//跟单时间
                remarks:'',//跟单内容
                result:null,//跟单结果
                remindDate:'',//提醒日期
                remindTime:'',//提醒时间
                trackRemarks:'',//备注
                orderContentImportant:'',//重要事项提醒
                importantRemindDate:'',//重要事项提醒日期
                importantRemindTime:'',//重要事项提醒时间
            },
            selectedImportantDate:new Date(),
            selectedRemindDate:new Date(),
            selectedFollowDate:new Date()
        }
    },

    methods: {
        releaseScroll(){
            document.getElementById('mainBody').scrollTop = 0
        },
        // 回访录音播放
        recordPlay(e){
            console.log(e)
            this.recordVisible = true;
            this.src = e.path
            this.recordTitle = e['customer_user_name']
        },
        //关闭录音
        stop(val) {
            this.src = ""
            this.recordTitle = ""
            this.recordVisible = val;
        },
        addDocumentation(){
            this.releaseScroll()//重置滚动条位置
            this.customer_info = {
                modality:null,//跟单方式
                follow_date:'',//跟单日期
                followTime:'',//跟单时间
                remarks:'',//跟单内容
                result:null,//跟单结果
                remindDate:'',//提醒日期
                remindTime:'',//提醒时间
                trackRemarks:'',//备注
                orderContentImportant:'',//重要事项提醒
                importantRemindDate:'',//重要事项提醒日期
                importantRemindTime:'',//重要事项提醒时间
            }
            this.follow_order_id = ''
            this.isEdit = false
        },
        editClick(row){
            this.releaseScroll()
            console.log(row)
            this.isEdit = false
            this.follow_order_id = row.id // 数据id
            this.customer_info.modalityView = row.follow_type !== '' &&  row.follow_type !== null? this.returnType(row.follow_type) : ''
            this.customer_info.modality = row.follow_type
            this.customer_info.follow_date =  row.follow_time ? moment(row.follow_time).format("YYYY-MM-DD") : ''
            this.customer_info.followTime = row.follow_time ? moment(row.follow_time).format("HH:mm") : ''
            this.customer_info.remarks = row.follow_content
            this.customer_info.result = row.follow_result
            this.customer_info.remindDate = row.remind_time ? moment(row.remind_time).format("YYYY-MM-DD") : ''
            this.customer_info.remindTime = row.remind_time ? moment(row.remind_time).format("HH:mm") : ''
            this.customer_info.trackRemarks = row.follow_remarks
            this.customer_info.orderContentImportant = row.important_matters
            this.customer_info.importantRemindDate = row.important_time ? moment(row.important_time).format("YYYY-MM-DD") : ''
            this.customer_info.importantRemindTime = row.important_time ? moment(row.important_time).format("HH:mm") : ''
        },
        exitEdit(){
            this.releaseScroll()//重置滚动条位置
            this.isEdit = true
        },
        saveEdit(){
            console.log(this.customer_info)
            if(!this.customer_info.follow_date) {
                Notify({type: 'warning', message: '请选择跟单日期'})
                return
            }
            // if(!this.customer_info.followTime) {
            //     Notify({type: 'warning', message: '请选择跟单时间'})
            //     return
            // }

            if(this.customer_info.modality === null) {
                Notify({type: 'warning', message: '请选择回访方式'})
                return
            }
            if(this.customer_info.remarks.trim() === '') {
                Notify({type: 'warning', message: '请输入跟单内容'})
                return
            }
            if(this.customer_info.result === null) {
                Notify({type: 'warning', message: '请选择跟单结果'})
                return
            }
            if(this.customer_info.remindDate && !this.customer_info.remindTime) {
                Notify({type: 'warning', message: '请选择提醒时间'})
                return
            }
            if(!this.customer_info.remindDate && this.customer_info.remindTime) {
                Notify({type: 'warning', message: '请选择提醒日期'})
                return
            }
            if(this.customer_info.importantRemindDate && !this.customer_info.importantRemindTime) {
                Notify({type: 'warning', message: '请选择提醒时间'})
                return
            }
            if(!this.customer_info.importantRemindDate && this.customer_info.importantRemindTime) {
                Notify({type: 'warning', message: '请选择提醒日期'})
                return
            }
          if (!this.customer_info.followTime){
            this.customer_info.followTime = '00:00'
          }
          let params = {
            id:this.follow_order_id,//数据id
            customer_id:this.customer_id,
            follow_time:this.customer_info.follow_date&&this.customer_info.followTime ? moment(this.customer_info.follow_date).format('YYYY-MM-DD') + ' ' + this.customer_info.followTime + ':00' : '',//跟单时间
            follow_type:this.customer_info.modality,//回访方式
            follow_content:this.customer_info.remarks,//跟单内容
            important_matters:this.customer_info.orderContentImportant,///重要事项提醒
            follow_remarks:this.customer_info.trackRemarks,//跟单备注
            follow_result:this.customer_info.result,//跟单结果
            remind_time:this.customer_info.remindDate&&this.customer_info.remindTime ? moment(this.customer_info.remindDate).format('YYYY-MM-DD') + ' ' + this.customer_info.remindTime + ':00' : '',//提醒时间
            important_time:this.customer_info.importantRemindDate&&this.customer_info.importantRemindTime ? moment(this.customer_info.importantRemindDate).format('YYYY-MM-DD') + ' ' + this.customer_info.importantRemindTime + ':00' : '',//重要事项提醒时间
          }
          console.log(params)
            this.saveDocumentary(params)
        },
        saveDocumentary(data){
            addDocumentary(data).then(res=>{
                if (res.code === 200){
                    this.isEdit = true
                    this.releaseScroll()//重置滚动条位置
                    this.getDocumentList()//跟单列表
                    Notify({
                        type: 'success',
                        message: '保存成功'
                    });
                }else{
                    Notify({
                        type: 'warning',
                        message: res['message']
                    });

                }
            }).catch(()=>{
                Notify({
                    type: 'warning',
                    message: '服务器连接失败'
                });
            })
        },
        projectConfirm(e){
            console.log(e,66)
          this.customer_info.modalityView = e.text
            this.customer_info.modality = e.value
            this.project_show = false;
        },
        // 回访方式(跟单记录)
        returnType(row) {
            let returnArr = ['电话', '微信', '邮件', 'QQ','WhatsApp']
            return returnArr[row]
        },
        openPicker() {
            // 每次打开弹框时，重置为当前时间
            this.customer_info.followTime = moment().format('HH:mm');
            this.followTime_show = true;
        },
        openRemindTimePicker(){
            this.customer_info.remindTime = moment().format('HH:mm');
            this.remindTime_show = true;
        },
        openImportantRemindTimePicker(){
            this.customer_info.importantRemindTime = moment().format('HH:mm');
            this.importantRemindTime_show = true;
        },
        remindDateConfirm(e){
            this.customer_info.remindDate = this.formatDate3(e)
            this.selectedRemindDate = e
            this.remindDate_show = false;
        },
        importantRemindDateConfirm(e){
            this.customer_info.importantRemindDate = this.formatDate3(e)
            this.selectedImportantDate = e
            this.importantRemindDate_show = false;
        },
        followTimeConfirm(e){
            this.customer_info.followTime = e
            this.followTime_show = false;
        },
        remindTimeConfirm(e){
            this.customer_info.remindTime = e
            this.remindTime_show = false;
        },
        importantRemindTimeConfirm(e){
            this.customer_info.importantRemindTime = e
            this.importantRemindTime_show = false;
        },
        companyBuildConfirm(e){
            this.customer_info.follow_date = this.formatDate3(e)
            this.selectedFollowDate = e
            this.company_build_show = false;
        },
        findIndex(data,list,type){
            for (let i = 0; i < list.length; i++) {
                if (list[i].value === data){
                    return i
                }
            }
            return 0
        },
        formatDate6(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatDate3(time) {
            return time ? moment(time).format("YYYY-MM-DD") : ''
        },
        // 获取跟单记录数据
        getDocumentList() {
            this.info_load = true
            getDocumentary(this.customer_id).then(data => {
                if (data.code === 200) {
                    //数据处理(path 录音路径、record_allow 按钮可用)
                    data.data.forEach((item, index) => {
                        if (item.follow_video) {
                            item.path = item.follow_video
                            item.record_allow = false
                        } else {
                            item.path = ''
                            item.record_allow = true
                        }
                    })
                    this.trackData = data.data
                    this.info_load = false
                    this.$showLoad()
                } else {
                    this.info_load = false
                    this.$showLoad()
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                this.info_load = false
                this.$showLoad()
                Notify({type: 'danger', message: '服务器连接失败!'})
            })
        },

    },
    created () {
        this.customer_id = this.$route.query.customer_id
        // this.customer_id = '174707'
        this.customer_name = this.$route.query.customer_name
        if (this.customer_id){
            this.getDocumentList()//跟单列表
        }
        else {
            this.$showLoad()
        }
    },
    mounted () {
    },

}
</script>

<style scoped>
.main-body{
    height: calc(100vh - 18px);
    padding: 18px 0 0;
    overflow-y: auto;
    background: #FFFFFF;
}
/*
.main-body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.main-body::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border-radius: 4px;
}

 */
.body-load{
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.title{
    width: 100%;
    min-height: 64px;
    padding: 10px 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(236,236,236,0.34);
    border-radius: 16px;
    border: 1px solid #F3F5F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-text{
    width: 60%;
    font-weight: 600;
    font-size: 16px;
    color: #272727;
}
.title-number{
     width: 40%;
    font-weight: 600;
    font-size: 14px;
    color: #419EFF;
  text-align: right;
}
.main-body-content{
    margin-bottom: 102px;
    padding: 0 10px;
}
.info-box{
    width: 100%;
    box-sizing: border-box;
    padding: 20px 15px;
}
.info-box{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(211,211,211,0.4);
    border-radius: 16px;
    border: 1px solid #F3F5F9;
    padding: 20px 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.info-box:last-child{
    margin-bottom: 0;
}

.info-box-title{
    width: 305px;
    font-weight: 400;
    font-size: 14px;
    color: #272727;
    line-height: 20px;
    word-wrap: break-word;
    word-break: break-all;
    margin-bottom: 15px;
}
.info-box-item{
    display: flex;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.info-box-item:last-child{
    margin-bottom: 0;
}

.info-box-item-title{
    width: 90px;
    font-weight: 400;
    font-size: 14px;
    color: #6F7379;
    line-height: 20px;
}
.info-box-item-title.info-box-item-title2{
    width: 142px;
}

.info-box-item-content{
    color: #272727;
    word-wrap: break-word;
    word-break: break-all;
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.footer-box{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 88px;
    background: #FFFFFF;
    box-shadow: 0 -2px 2px 0 rgba(228,228,228,0.5);
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.default-btn{
    width: calc((100% - 15px) / 2);
    box-sizing: border-box;
    height: 50px;
}
.footer-btn{
    width: calc((100% - 15px) / 2);
    box-sizing: border-box;
    height: 50px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #E5E5E5;
    color: #333333;
}
.footer-btn.footer-btn2{
    border: none;
    background: #409EFF;
    color: #FFFFFF;
}

.record-button{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.operate-btn{
    width: calc((100% - 15px) / 2);
    border-radius: 20px;
    font-weight: 400;
    font-size: 16px;
}
.sound-btn{
    border: 1px solid #E5E5E5;
}
.edit-btn{
    background: #419EFF;
    color: #FFFFFF;
}
.info-box-edit-title{
    background: #F5F5FC;
    width: 100%;
    box-sizing: border-box;
    padding: 11px 20px 11px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #272727;
    line-height: 20px;
    margin-bottom: 20px;
}
.info-box-edit-form{
    padding: 0 10px;
}
.info-box-edit-form-item{
    margin-bottom: 16px;
}

.info-box-edit-form-item:last-child{
    margin-bottom: 20px;
}
.info-edit-form-time{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.info-edit-form-follow_date{
    width: 150px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    overflow: hidden;
}
.info-edit-form-follow_time{
    width: calc(100% - 160px);
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    overflow: hidden;
}
.info-edit-form-input{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    overflow: hidden;
}

.info-edit-form-input >>> .van-cell{
    padding: 0;
    height: 100%;
}

.info-edit-form-input >>> .van-cell .van-field__body{
    height: 100%;
}

.info-edit-form-input >>> .van-cell .van-field__control{
    padding: 0 10px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    color: #606266;
}
.info-edit-form-input >>> .van-cell .van-field__clear{
    right: 10px;
}

.info-edit-form-input.info-edit-form-star{
    border-color: #F56C6C;
}
.info-edit-form-title{
    font-weight: 600;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}
.info-edit-form-title span{
    font-weight: 400;
    font-size: 13px;
    color: #F56C6C;
}
.info-edit-form-sel{
    margin-top: 12px;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
}
.info-edit-form-checkbox >>> .van-cell{
    height: 100px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    margin: 16px 0;
}
.info-edit-form-checkbox >>> .van-cell::after{
    border: none;
}
.info-edit-form-checkbox >>> .van-cell .van-field__body{
    height: 100%;
}
.info-edit-form-checkbox >>> .van-cell .van-field__body .van-field__control{
    height: 100%;
}
.followTimeStyle{
    padding: 10px 0 10px 10px;
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
}
.info-box-out{
    padding-bottom: 88px;
}

.empty-box{
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}
.center{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.img-box{
  display: flex;
  justify-content: center;
}
.info-box-edit-star-tip{
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    color: #FF8522;
    line-height: 18px;
    word-wrap: break-word;
    word-break: break-all;
    background: #FFF9F4;
    margin-bottom: 15px;
}
</style>
