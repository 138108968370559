<template>
    <div class="main-body">
<!--        <van-loading class="body-load" v-if="info_load"/>-->
        <div class="binding-box">
            <div class="info-tab">
                <div
                  class="info-tab-item"
                  :class="{'info-tab-item-active': item.id === tab_index}"
                  v-for="(item,index) in tab_list"
                  :key="index"
                  @click="selTab(item)"
                >
                    {{ item.title }}
                </div>
            </div>
            <template v-if="tab_index === 1 && !info_load">
                <div v-if="!editState" id="info-box-out" class="info-box-out" :class="!editState? 'info-box-out2' : '' ">
                    <div class="info-box">
                        <div class="info-box-title">{{ customer_info.info.user_name }}</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">客户序号</div>
                            <div class="info-box-item-content">{{ customer_info.info.id }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">客户等级</div>
                            <div class="info-box-item-content">{{ levelName(customer_info.info.customer_grade) }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">性别</div>
                            <div class="info-box-item-content">{{ customer_info.info.user_sex == 1 ? '男' : customer_info.info.user_sex == 2 ? '女' : '未知' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">年龄</div>
                            <div class="info-box-item-content">{{ customer_info.info.birthday ? customer_info.info.birthday + '岁' : '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">外语水平</div>
                            <div class="info-box-item-content">{{ customer_info.info.language_ability || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">工作年限</div>
                            <div class="info-box-item-content">{{ customer_info.info.work_life || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">资金状况</div>
                            <div class="info-box-item-content">{{ customer_info.info.capital_preparation || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">职业信息</div>
                            <div class="info-box-item-content">{{ customer_info.info.work || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">学历</div>
                            <div class="info-box-item-content">{{ customer_info.info.education ? education_list.find(i => i.value == customer_info.info.education)?.text : '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">毕业院校</div>
                            <div class="info-box-item-content">{{ customer_info.info.school || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">专业</div>
                            <div class="info-box-item-content">{{ customer_info.info.speciality || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">客户地址</div>
                            <div class="info-box-item-content">{{ customer_info.info.province_m || '-' }}</div>
                        </div>
                        <div class="info-box-item" v-if="customer_info.info.source_name === '自定义来源' || customer_info.info.source_name === '老客户介绍'">
                            <div class="info-box-item-title">{{ customer_info.info.source_name === '老客户介绍' ? '老客户序号' : customer_info.info.source_name }}</div>
                            <div class="info-box-item-content">{{ customer_info.info.source_text }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">跟单顾问</div>
                            <div class="info-box-item-content">{{ customer_info.info.follow_counsel_name || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">运营客服</div>
                            <div class="info-box-item-content">{{ customer_info.info.official_service_name || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">所属公司</div>
                            <div class="info-box-item-content">{{ customer_info.info.company_name || '-' }}</div>
                        </div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">资源类别</div>
                            <div class="info-box-item-content">{{ customer_info.info.cat_name || '-' }}</div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="info-box-title">星标客户</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">星标客户</div>
                            <div class="info-box-item-content">{{ customer_info.info.is_start == 0 ? '已摘星' : customer_info.info.is_start == 1 ? '已挂星' : '未挂星' }}</div>
                        </div>
                        <div class="info-box-item" v-if="customer_info.info.is_start == 0 || customer_info.info.is_start == 1">
                            <div class="info-box-item-title">挂星时间</div>
                            <div class="info-box-item-content">{{ formatDate(customer_info.info.start_time) }}</div>
                        </div>
                        <div class="info-box-item" v-if="customer_info.info.is_start == 0">
                            <div class="info-box-item-title">摘星时间</div>
                            <div class="info-box-item-content">{{ formatDate(customer_info.info.starpicking_time) }}</div>
                        </div>
                        <div class="info-box-item" v-if="customer_info.info.is_start == 0">
                            <div class="info-box-item-title">摘星原因</div>
                            <div class="info-box-item-content">
                                <div class="info-box-item-list" v-for="(item,index) in customer_info.info.reason">
                                    <div class="info-box-item-list-title">{{ item.title }}</div>
                                    <div class="info-box-item-list-text">{{ item.content }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="info-box-title">意向国家</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">意向国家</div>
                            <div class="info-box-item-content">{{ customer_info.info.intentional_state || '-' }}</div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="info-box-title">企业情况</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">企业主情况</div>
                            <div class="info-box-item-content">{{ customer_info.info.is_company == 1 ? '是企业主' : customer_info.info.is_company == 2 ? '不是企业主' : '-' }}</div>
                        </div>
                        <template v-if="customer_info.info.is_company == 1">
                            <div class="info-box-item">
                                <div class="info-box-item-title">主营业务</div>
                                <div class="info-box-item-content">{{ customer_info.info.business || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">企业规模</div>
                                <div class="info-box-item-content">{{ customer_info.info.scale || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">成立时间</div>
                                <div class="info-box-item-content">{{ customer_info.info.founding_time || '-' }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="info-box">
                        <div class="info-box-title">护照及绿卡情况</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title info-box-item-title2">其他国家护照情况</div>
                            <div class="info-box-item-content">{{ customer_info.info.third_party_country_status == 1 ? '有其他国家护照' : '没有其他国家护照' }}</div>
                        </div>
                        <template v-if="customer_info.info.third_party_country_status == 1">
                            <div class="info-box-item">
                                <div class="info-box-item-title info-box-item-title2">其他国家护照详情</div>
                                <div class="info-box-item-content">{{ customer_info.info.third_party_country || '' }}</div>
                            </div>
                        </template>
                        <div class="info-box-item">
                            <div class="info-box-item-title info-box-item-title2">其他国家绿卡情况</div>
                            <div class="info-box-item-content">{{ customer_info.info.green_card_status == 1 ? '有其他国家绿卡' : '没有其他国家绿卡' }}</div>
                        </div>
                        <template v-if="customer_info.info.green_card_status == 1">
                            <div class="info-box-item">
                                <div class="info-box-item-title info-box-item-title2">其他国家绿卡详情</div>
                                <div class="info-box-item-content">{{ customer_info.info.green_card || '' }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="info-box">
                        <div class="info-box-title">婚姻状况及配偶信息</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title">婚姻状况</div>
                            <div class="info-box-item-content" v-if="customer_info.info.marriage == 0">未知</div>
                            <div class="info-box-item-content" v-else-if="customer_info.info.marriage == 1">已婚</div>
                            <div class="info-box-item-content" v-else-if="customer_info.info.marriage == 2">未婚</div>
                            <div class="info-box-item-content" v-else-if="customer_info.info.marriage == 3">离异</div>
                            <div class="info-box-item-content" v-else-if="customer_info.info.marriage == 4">丧偶</div>
                            <div class="info-box-item-content" v-else>-</div>
                        </div>
                        <template v-if="customer_info.info.marriage == 1">
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶序号</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.id || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶姓名</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.user_name || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶年龄</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.birthday ? customer_info.marriage.birthday + '岁' : '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶学历</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.education ? education_list.find(i => i.value == customer_info.marriage.education)?.text : '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">外语水平</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.language_ability || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶专业</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.speciality || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶职业</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.work || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">工作年限</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.work_life || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶电话</div>
                                <div class="info-box-item-content">{{ (customer_info.marriage.number_country_id && customer_info.marriage.user_phone) ? area_code_list.find(i => i.value == customer_info.marriage.number_country_id)?.code + ' ' + customer_info.marriage.user_phone : '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶微信</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.user_chat || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶邮箱</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.user_email || '-' }}</div>
                            </div>
                            <div class="info-box-item">
                                <div class="info-box-item-title">配偶QQ</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.user_qq || '-' }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="info-box" v-if="customer_info.info.marriage == 1">
                        <div class="info-box-title">配偶护照及绿卡情况</div>
                        <div class="info-box-item">
                            <div class="info-box-item-title info-box-item-title2">其他国家护照情况</div>
                            <div class="info-box-item-content">{{ customer_info.marriage.third_party_country_status == 1 ? '有其他国家护照' : '没有其他国家护照' }}</div>
                        </div>
                        <template v-if="customer_info.marriage.third_party_country_status == 1">
                            <div class="info-box-item">
                                <div class="info-box-item-title info-box-item-title2">其他国家护照详情</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.third_party_country || '' }}</div>
                            </div>
                        </template>
                        <div class="info-box-item">
                            <div class="info-box-item-title info-box-item-title2">其他国家绿卡情况</div>
                            <div class="info-box-item-content">{{ customer_info.marriage.green_card_status == 1 ? '有其他国家绿卡' : '没有其他国家绿卡' }}</div>
                        </div>
                        <template v-if="customer_info.marriage.green_card_status == 1">
                            <div class="info-box-item">
                                <div class="info-box-item-title info-box-item-title2">其他国家绿卡详情</div>
                                <div class="info-box-item-content">{{ customer_info.marriage.green_card || '' }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="info-box">
                        <div class="info-box-title">咨询备注</div>
                        <div class="info-box-item">
                            <div class="info-box-item-content">{{ customer_info.info.remarks || '-' }}</div>
                        </div>
                    </div>

                </div>
                <div v-else id="info-box-out" class="info-box-out">
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">基本信息</div>
                    </div>
                    <div class="info-box-edit-form">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">客户名称</div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.user_name"
                                  placeholder="输入客户名称"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">性别<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  v-model="sex_value"
                                  placeholder="选择性别"
                                  @click="sex_show = true"
                                />
                                <van-popup v-model:show="sex_show" position="bottom">
                                    <van-picker
                                      :columns="sex_list"
                                      @confirm="sexConfirm"
                                      @cancel="sex_show = false"
                                      :default-index="findIndex(customer_info.info.user_sex,sex_list,2)"
                                    />
                                </van-popup>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">学历<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  v-model="education_value"
                                  placeholder="选择学历"
                                  @click="education_show = true"
                                />
                                <van-popup v-model:show="education_show" position="bottom">
                                    <van-picker
                                      :columns="education_list"
                                      @confirm="educationConfirm"
                                      @cancel="education_show = false"
                                      :default-index="findIndex(customer_info.info.education,education_list,2)"
                                    />
                                </van-popup>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">毕业院校</div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.school"
                                  placeholder="输入毕业院校"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">专业</div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.speciality"
                                  placeholder="输入专业"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">外语水平</div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.language_ability"
                                  placeholder="输入外语水平"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">工作年限</div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.work_life"
                                  placeholder="输入工作年限"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">职业信息<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.work"
                                  placeholder="输入职业信息"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="customer_info.info.is_start == 1">
                            <div class="info-edit-form-title">意向签约项目<span v-if="customer_info.info.is_start == 1">（挂星选填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  v-model="project_value"
                                  placeholder="选择意向签约项目"
                                  @click="project_show = true"
                                />
                                <van-popup v-model:show="project_show" position="bottom">
                                    <van-picker
                                      :default-index="findIndex(customer_info.info.intention_project,sign_data,1)"
                                      :columns="sign_data"
                                      @confirm="projectConfirm"
                                      @cancel="project_show = false"
                                    />
                                </van-popup>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">资金状况</div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.capital_preparation"
                                  placeholder="输入资金状况"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">年龄<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.birthday"
                                  placeholder="输入年龄"
                                  type="digit"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">客户地址<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  v-model="province_name"
                                  placeholder="选择客户地址"
                                  @click="province_show = true"
                                />
                                <van-popup v-model:show="province_show" position="bottom">
                                    <van-cascader
                                      v-model="province_value"
                                      active-color="#419EFF"
                                      :options="province_list"
                                      :field-names="province_set"
                                      @close="province_show = false"
                                      @finish="provinceFinish"
                                    />
                                </van-popup>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">客户等级<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  v-model="grade_value"
                                  placeholder="选择客户等级"
                                  @click="grade_show = true"
                                />
                                <van-popup v-model:show="grade_show" position="bottom">
                                    <van-picker
                                      class="grade_pick"
                                      allow-html
                                      :default-index="findIndex(customer_info.info.customer_grade,grade_list,2)"
                                      :columns="grade_list"
                                      @confirm="gradeConfirm"
                                      @cancel="grade_show = false"
                                    />
                                </van-popup>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="customer_info.info.customer_grade == 6">
                            <div class="info-edit-form-title">无效原因<span>（必填）</span></div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  v-model="invalid_value"
                                  placeholder="选择无效原因"
                                  @click="invalid_show = true"
                                />
                                <van-popup v-model:show="invalid_show" position="bottom">
                                    <van-picker
                                      :default-index="findIndex(customer_info.info.invalid_reason,invalid_arr,1)"
                                      :columns="invalid_arr"
                                      @confirm="invalidConfirm"
                                      @cancel="invalid_show = false"
                                    />
                                </van-popup>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="other_status == 1">
                            <div class="info-edit-form-title">具体情况说明<span>（必填）</span></div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.invalid_reason_content"
                                  placeholder="输入具体情况说明"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="customer_info.info.customer_grade == 5">
                            <div class="info-edit-form-title">评级原因<span>（必填）</span></div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.e_grade_reason"
                                  placeholder="输入评级原因"
                                  clearable
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">星标客户</div>
                        <img class="info-box-edit-star" :class="!star_edit_show ? 'info-box-edit-star-show' : ''" src="@/assets/star-show.svg" @click="star_edit_show = !star_edit_show"/>
                    </div>
                    <div class="info-box-edit-star-tip" v-if="star_edit_show">挂星条件：1、移民目的需求大，必走；2、已了解项目框架、流程和费用；3、家里都同意移民；4、预算符合：满足办理费用+基础移民成本；5、符合我们在售移民产品签约门槛；6、计划3个月内办理并具备逼单条件。
                        <br/>挂星必填：1、客户等级划分在C+以上；2、年龄；3、性别；4、客户地址；5、意向国家；6、学历；7、职业信息；8、有跟单记录；9、意向签约项目（选填）。
                    </div>
                    <div class="info-box-edit-form" v-if="star_edit_show">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">星标状态</div>
                            <div class="info-edit-form-sel">
                                <van-radio-group
                                  v-model="customer_info.info.is_start"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="changeStar"
                                >
                                    <van-radio :name="1">设为星标客户</van-radio>
                                    <van-radio :name="0" :disabled="starStatus != 0 && starStatus != 1">取消星标客户</van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="starStatus == 0 || starStatus == 1">
                            <div class="info-edit-form-title">挂星时间</div>
                            <div class="info-edit-form-sel">
                                {{ formatDate(customer_info.info.start_time) }}
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="starStatus == 0">
                            <div class="info-edit-form-title">摘星时间</div>
                            <div class="info-edit-form-sel">
                                {{ formatDate(customer_info.info.starpicking_time) }}
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="star_show">
                            <div class="info-edit-form-title">摘星原因</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox info-edit-form-textarea">
                                <van-checkbox-group
                                  v-model="starCheckList"
                                  @change="starOutChange"
                                >
                                    <template v-for="(item,index) in star_out_list">
                                        <van-checkbox
                                          :class="starCheckList.includes(item.id) ? 'star-reason-active' : '' "
                                          icon-size="14"
                                          checked-color="#419EFF"
                                          shape="square"
                                          :name="item.id"
                                        >
                                            {{ item.title }}
                                        </van-checkbox>
                                        <van-field
                                          autocomplete="off"
                                          v-if="starCheckList.includes(item.id)"
                                          v-model="item.content"
                                          type="textarea"
                                          maxlength="200"
                                          show-word-limit
                                          placeholder="输入具体原因（必填）"
                                        />
                                    </template>
                                </van-checkbox-group>
                            </div>
                        </div>
                    </div>
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">意向国家</div>
                    </div>
                    <div class="info-box-edit-form">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">意向国家<span v-if="customer_info.info.is_start == 1">（挂星必填）</span>
                            </div>
                            <div class="info-edit-form-input" :class="{'info-edit-form-star' : customer_info.info.is_start == 1}">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.intentional_state"
                                  placeholder="输入意向国家"
                                  clearable
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">企业情况</div>
                    </div>
                    <div class="info-box-edit-form">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">企业主情况</div>
                            <div class="info-edit-form-sel">
                                <van-radio-group
                                  v-model="customer_info.info.is_company"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="changeCompany"
                                >
                                    <van-radio :name="1">是企业主</van-radio>
                                    <van-radio :name="2">不是企业主</van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="customer_info.info.is_company == 1">
                            <div class="info-edit-form-title">企业规模<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                            </div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.scale"
                                  placeholder="输入企业规模"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="customer_info.info.is_company == 1">
                            <div class="info-edit-form-title">主营业务<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                            </div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  v-model="customer_info.info.business"
                                  placeholder="输入主营业务"
                                  clearable
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item" v-if="customer_info.info.is_company == 1">
                            <div class="info-edit-form-title">企业成立时间<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                            </div>
                            <div class="info-edit-form-input">
                                <van-field
                                  autocomplete="off"
                                  readonly
                                  clickable
                                  name="picker"
                                  v-model="company_build_value"
                                  placeholder="选择企业成立时间"
                                  @click="company_build_show = true"
                                />
                                <van-popup v-model:show="company_build_show" position="bottom">
                                    <van-datetime-picker
                                      v-model="company_build_time"
                                      type="date"
                                      :min-date="company_build_minDate"
                                      @confirm="companyBuildConfirm"
                                      @cancel="company_build_show = false"
                                    />
                                </van-popup>
                            </div>
                        </div>
                    </div>
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">护照及绿卡情况</div>
                    </div>
                    <div class="info-box-edit-form">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">其他国家护照情况</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox info-edit-form-textarea">
                                <van-radio-group
                                  v-model="customer_info.info.third_party_country_status"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="changeThirdParty"
                                >
                                    <van-radio :name="1">有其他国家护照</van-radio>
                                    <van-radio :name="0">没有其他国家护照</van-radio>
                                </van-radio-group>
                                <van-field
                                  autocomplete="off"
                                  v-if="customer_info.info.third_party_country_status == 1"
                                  v-model="customer_info.info.third_party_country"
                                  type="textarea"
                                  maxlength="200"
                                  show-word-limit
                                  placeholder="输入其他国家护照详情"
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">其他国家绿卡情况</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox info-edit-form-textarea">
                                <van-radio-group
                                  v-model="customer_info.info.green_card_status"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="changeGreenCard"
                                >
                                    <van-radio :name="1">有其他国家绿卡</van-radio>
                                    <van-radio :name="2">没有其他国家绿卡</van-radio>
                                </van-radio-group>
                                <van-field
                                  autocomplete="off"
                                  v-if="customer_info.info.green_card_status == 1"
                                  v-model="customer_info.info.green_card"
                                  type="textarea"
                                  maxlength="200"
                                  show-word-limit
                                  placeholder="输入其他国家绿卡详情"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">婚姻状况</div>
                    </div>
                    <div class="info-box-edit-form">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">婚姻状况</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox">
                                <van-radio-group
                                  v-model="customer_info.info.marriage"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="changeMarriage"
                                >
                                    <van-radio :name="0">未知</van-radio>
                                    <van-radio :name="1">已婚</van-radio>
                                    <van-radio :name="2">未婚</van-radio>
                                    <van-radio :name="3">离异</van-radio>
                                    <van-radio :name="4">丧偶</van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                        <template v-if="customer_info.info.marriage == 1">
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶序号</div>
                                <div class="spouse-form-input">
                                    <div class="info-edit-form-input">
                                        <van-field
                                          autocomplete="off"
                                          type="digit"
                                          v-model="customer_info.marriage.id"
                                          placeholder="输入配偶序号"
                                          clearable
                                        />
                                    </div>
                                    <van-button
                                      class="spouse-btn"
                                      type="default"
                                      @click="spouseData"
                                      :loading="spouse_load"
                                    >搜索</van-button>
                                </div>

                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶名称<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.user_name"
                                      placeholder="输入配偶名称"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶年龄<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.birthday"
                                      placeholder="输入配偶年龄"
                                      type="digit"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶电话<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input info-edit-form-phone">
                                    <van-dropdown-menu
                                      class="phone-country"
                                      active-color="#419EFF"
                                    >
                                        <van-dropdown-item ref="countryId" :title="area_code_list.find(i=>i.value == customer_info.marriage.number_country_id)?.code">
                                            <div class="phone-country-item" v-for="(item,index) in area_code_list" :key="index" @click="changeCountryId(item)">
                                                <div class="phone-country-item-num">{{ item.code }}</div>
                                                <div class="phone-country-item-name">{{ item.text }}</div>

                                            </div>
                                        </van-dropdown-item>
                                    </van-dropdown-menu>
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.user_phone"
                                      placeholder="输入配偶电话"
                                      type="tel"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶微信<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.user_chat"
                                      placeholder="输入配偶微信"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶邮箱</div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.user_email"
                                      placeholder="输入配偶邮箱"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶QQ</div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.user_qq"
                                      placeholder="输入配偶QQ"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶学历<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      readonly
                                      v-model="sub_education_value"
                                      placeholder="选择学历"
                                      @click="sub_education_show = true"
                                    />
                                    <van-popup v-model:show="sub_education_show" position="bottom">
                                        <van-picker
                                          :columns="education_list"
                                          :default-index="findIndex(customer_info.marriage.education,education_list,2)"
                                          @confirm="subEducationConfirm"
                                          @cancel="sub_education_show = false"
                                        />
                                    </van-popup>
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶专业<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.speciality"
                                      placeholder="输入配偶专业"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">外语水平</div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.language_ability"
                                      placeholder="输入外语水平"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">配偶职业<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.work"
                                      placeholder="输入配偶职业"
                                      clearable
                                    />
                                </div>
                            </div>
                            <div class="info-box-edit-form-item">
                                <div class="info-edit-form-title">工作年限<span v-if="[2,3,4].includes(customer_info.info.customer_grade)">（必填）</span>
                                </div>
                                <div class="info-edit-form-input">
                                    <van-field
                                      autocomplete="off"
                                      v-model="customer_info.marriage.work_life"
                                      placeholder="输入工作年限"
                                      clearable
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="info-box-edit-title" v-if="customer_info.info.marriage == 1">
                        <div class="info-box-edit-title-in">配偶护照及绿卡情况</div>
                    </div>
                    <div class="info-box-edit-form" v-if="customer_info.info.marriage == 1">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">其他国家护照情况</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox info-edit-form-textarea">
                                <van-radio-group
                                  v-model="customer_info.marriage.third_party_country_status"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="subChangeThirdParty"
                                >
                                    <van-radio :name="1">有其他国家护照</van-radio>
                                    <van-radio :name="0">没有其他国家护照</van-radio>
                                </van-radio-group>
                                <van-field
                                  autocomplete="off"
                                  v-if="customer_info.marriage.third_party_country_status == 1"
                                  v-model="customer_info.marriage.third_party_country"
                                  type="textarea"
                                  maxlength="200"
                                  show-word-limit
                                  placeholder="输入其他国家护照详情"
                                />
                            </div>
                        </div>
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">其他国家绿卡情况</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox info-edit-form-textarea">
                                <van-radio-group
                                  v-model="customer_info.marriage.green_card_status"
                                  direction="horizontal"
                                  icon-size="13"
                                  checked-color="#419EFF"
                                  @change="subChangeGreenCard"
                                >
                                    <van-radio :name="1">有其他国家绿卡</van-radio>
                                    <van-radio :name="2">没有其他国家绿卡</van-radio>
                                </van-radio-group>
                                <van-field
                                  autocomplete="off"
                                  v-if="customer_info.marriage.green_card_status == 1"
                                  v-model="customer_info.marriage.green_card"
                                  type="textarea"
                                  maxlength="200"
                                  show-word-limit
                                  placeholder="输入其他国家绿卡详情"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-box-edit-title">
                        <div class="info-box-edit-title-in">备注</div>
                    </div>
                    <div class="info-box-edit-form">
                        <div class="info-box-edit-form-item">
                            <div class="info-edit-form-title">咨询备注</div>
                            <div class="info-edit-form-sel info-edit-form-checkbox info-edit-form-textarea" style="margin-top: 0;">
                                <van-field
                                  autocomplete="off"
                                  style="margin-top: 0;"
                                  v-model="customer_info.info.remarks"
                                  type="textarea"
                                  maxlength="300"
                                  show-word-limit
                                  placeholder="输入咨询备注"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </template>
            <template v-if="tab_index === 2 && !info_load">
                <div v-if="!editState" id="info-box-out" class="info-box-out info-box-out2">
                    <div class="contact-info-box">
                        <div class="contact-info-type">
                            <div class="contact-info-type-name">联系方式优先级</div>
                            <div class="contact-info-type-text" v-if="customer_info.info.contact_information_first === 0">电话</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 1">微信</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 2">微信</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 3">QQ</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 4">WhatsApp</div>
                            <div class="contact-info-type-text" v-else>-</div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            电话
                        </div>
                        <div class="contact-info-phone-list">
                            <div class="contact-info-phone-item" v-for="(item,index) in phoneTabList" :key="index">
                                <div class="contact-info-phone-item-left contact-info-phone-item-left2">
                                    <div class="contact-info-phone-num">{{ '电话' + (index ? index : '') }}</div>
                                    <div class="contact-info-phone-value">{{ item.value ? area_code_list.find(i => i.value == item.nc_id)?.code + ' ' + item.value : '-' }}</div>
                                    <div class="contact-info-phone-name" v-if="index !== 0">{{ item.name }}</div>
                                </div>
                                <div v-if="item.value" class="contact-info-phone-call" :class="{'phone-call-disable':(!item.call && call) || callLoad }">
                                    <img v-if="!item.call" src="@/assets/call-bule.svg" @click="callOutPro(item,true)"/>
                                    <img v-else src="@/assets/call-red.svg" @click="callOutPro(item,false)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            微信
                        </div>
                        <div class="contact-info-phone-list">
                            <div class="contact-info-phone-item" v-for="(item,index) in wxTabList.slice(0,1)">
                                <div class="contact-info-phone-item-left" v-if="index === 0">
                                    <div class="contact-info-phone-num contact-info-phone-num3">微信</div>
                                    <div class="contact-info-phone-value" v-if="item.wx_type == 2">{{ item.value || '-' }}</div>
                                    <div class="contact-info-phone-name" v-else-if="item.wx_type == 1">点击右侧图标添加客户二维码</div>
                                    <div class="contact-info-phone-name2" v-else-if="item.wx_type == 0">{{ item.adviser_path.code_title }}</div>
                                    <div class="contact-info-phone-value" v-if="item.wx_type == 0">/点击右侧图标添加客服二维码</div>
                                    <div class="contact-info-phone-call" v-if="item.wx_type == 1 || item.wx_type == 0">
                                        <img src="@/assets/add-wx.svg" @click="openQr(item)"/>
                                    </div>
                                </div>

                            </div>
                            <div class="contact-info-phone-item" v-if="wxTabList[0]?.wx_type == 0">
                                <div class="contact-info-phone-item-left">
                                    <div class="contact-info-phone-num contact-info-phone-num3">微信号</div>
                                    <div class="contact-info-phone-value">{{ wxTabList[0].value }}</div>
                                </div>
                            </div>
                            <div class="contact-info-phone-item" v-for="(item,index) in wxTabList.slice(1)" :key="index">
                                <div class="contact-info-phone-item-left">
                                    <div class="contact-info-phone-num contact-info-phone-num3">{{ '微信' + (index + 1) }}</div>
                                    <div class="contact-info-phone-value">{{ item.value }}</div>
                                    <div class="contact-info-phone-name">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            WhatsAPP
                        </div>
                        <div class="contact-info-phone-list">
                            <div class="contact-info-phone-item" v-for="(item,index) in whatTabList" :key="index">
                                <div class="contact-info-phone-item-left">
                                    <div class="contact-info-phone-num contact-info-phone-num2">{{ 'WhatsAPP' + (index ? index : '') }}</div>
                                    <div class="contact-info-phone-value" v-if="index === 0 && !item.value">-</div>
                                    <div class="contact-info-phone-value" v-else>{{ area_code_list.find(i => i.value == item.nc_id)?.code + ' ' + item.value }}</div>
                                    <div class="contact-info-phone-name" v-if="index !== 0">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            邮箱
                        </div>
                        <div class="contact-info-phone-list">
                            <div class="contact-info-phone-item" v-for="(item,index) in emailTabList" :key="index">
                                <div class="contact-info-phone-item-left">
                                    <div class="contact-info-phone-num contact-info-phone-num3">{{ '邮箱' + (index ? index : '') }}</div>
                                    <div class="contact-info-phone-value" v-if="index === 0 && !item.value">-</div>
                                    <div class="contact-info-phone-value" v-else>{{ item.value }}</div>
                                    <div class="contact-info-phone-name" v-if="index !== 0">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            QQ
                        </div>
                        <div class="contact-info-phone-list">
                            <div class="contact-info-phone-item" v-for="(item,index) in qqTabList" :key="index">
                                <div class="contact-info-phone-item-left">
                                    <div class="contact-info-phone-num contact-info-phone-num3">{{ 'QQ' + (index ? index : '') }}</div>
                                    <div class="contact-info-phone-value" v-if="index === 0 && !item.value">-</div>
                                    <div class="contact-info-phone-value" v-else>{{ item.value }}</div>
                                    <div class="contact-info-phone-name" v-if="index !== 0">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else id="info-box-out" class="info-box-out info-box-out2">
                    <div class="contact-info-box">
                        <div class="contact-info-type">
                            <div class="contact-info-type-name">联系方式优先级</div>
                            <div class="contact-info-type-text" v-if="customer_info.info.contact_information_first === 0">电话</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 1">微信</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 2">微信</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 3">QQ</div>
                            <div class="contact-info-type-text" v-else-if="customer_info.info.contact_information_first === 4">WhatsApp</div>
                            <div class="contact-info-type-text" v-else>-</div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            电话
                            <van-icon name="add" class="add-info" @click="addPhone"/>
                        </div>
                        <div class="contact-info-add-list">
                            <div class="contact-info-add-list-item" v-for="(item,index) in phoneTabList" :key="item.id">
                                <div class="contact-info-add-item-label">{{ '电话' + (index ? index : '') }}</div>
                                <div class="contact-info-add-item-value" v-if="index === 0">{{ item.value ? area_code_list.find(i => i.value == item.nc_id)?.code + ' ' + item.value : '-' }}</div>
                                <div class="contact-info-add-item-edit" v-if="index > 0">
                                    <div class="info-edit-form-input info-add-item-name">
                                        <van-field
                                          autocomplete="off" v-model="item.name" placeholder="姓名" clearable/>
                                    </div>
                                    <div class="info-edit-form-input info-edit-form-phone">
                                        <van-dropdown-menu
                                          class="phone-country"
                                          active-color="#419EFF"
                                        >
                                            <van-dropdown-item :ref="'phone' + index" :title="area_code_list.find(i=>i.value == item.nc_id)?.code">
                                                <div class="phone-country-item" v-for="(item2,index2) in area_code_list" :key="index2" @click="changePhoneCountry(item2,item,index,true)">
                                                    <div class="phone-country-item-num">{{ item2.code }}</div>
                                                    <div class="phone-country-item-name">{{ item2.text }}</div>

                                                </div>
                                            </van-dropdown-item>
                                        </van-dropdown-menu>
                                        <van-field
                                          autocomplete="off"
                                          v-model="item.value"
                                          placeholder="输入电话"
                                          type="tel"
                                          clearable
                                        />
                                    </div>
                                    <div class="info-add-item-del" @click="removePhone(item.id)">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            微信
                            <van-icon name="add" class="add-info" @click="addWx"/>
                        </div>
                        <div class="contact-info-add-list">
                            <div class="contact-info-add-list-item" v-for="(item,index) in wxTabList" :key="item.id">
                                <div class="contact-info-add-item-label">{{ '微信' + (index ? index : '') }}</div>
                                <div class="contact-info-add-item-value" v-if="index === 0 && item.wx_type == 2">{{ item.value || '-' }}</div>
                                <div class="contact-info-phone-item-left contact-info-add-item-value" v-if="index === 0 && (item.wx_type == 1 || item.wx_type == 0)">
                                    <div class="contact-info-phone-name" v-if="item.wx_type == 1">点击右侧图标添加客户二维码</div>
                                    <div class="contact-info-phone-name" v-else-if="item.wx_type == 0">{{ item.adviser_path.code_title }}</div>
                                    <div class="contact-info-phone-value" v-if="item.wx_type == 0">/点击右侧图标添加客服二维码</div>
                                    <div class="contact-info-phone-call">
                                        <img src="@/assets/add-wx.svg" @click="openQr(item)"/>
                                    </div>
                                </div>
                                <div class="contact-info-add-item-label" v-if="index === 0 && item.wx_type == 0">微信号</div>
                                <div class="contact-info-add-item-value" v-if="index === 0 && item.wx_type == 0">{{ item.value }}</div>
                                <div class="contact-info-add-item-edit" v-if="index > 0">
                                    <div class="info-edit-form-input info-add-item-name">
                                        <van-field
                                          autocomplete="off" v-model="item.name" placeholder="姓名" clearable/>
                                    </div>
                                    <div class="info-edit-form-input info-edit-form-phone">
                                        <van-field
                                          autocomplete="off"
                                          v-model="item.value"
                                          placeholder="输入微信"
                                          clearable
                                        />
                                    </div>
                                    <div class="info-add-item-del" @click="removeWx(item.id)">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            WhatsAPP
                            <van-icon name="add" class="add-info" @click="addWhatsApp"/>
                        </div>
                        <div class="contact-info-add-list">
                            <div class="contact-info-add-list-item" v-for="(item,index) in whatTabList" :key="item.id">
                                <div class="contact-info-add-item-label">{{ 'WhatsAPP' + (index ? index : '') }}</div>
                                <div class="contact-info-add-item-value" v-if="index === 0">{{ item.value ? area_code_list.find(i => i.value == item.nc_id)?.code + ' ' + item.value : '-' }}</div>
                                <div class="contact-info-add-item-edit" v-if="index > 0">
                                    <div class="info-edit-form-input info-add-item-name">
                                        <van-field
                                          autocomplete="off" v-model="item.name" placeholder="姓名" clearable/>
                                    </div>
                                    <div class="info-edit-form-input info-edit-form-phone">
                                        <van-dropdown-menu
                                          class="phone-country"
                                          active-color="#419EFF"
                                        >
                                            <van-dropdown-item :ref="'WhatsAPP' + index" :title="area_code_list.find(i=>i.value == item.nc_id)?.code">
                                                <div class="phone-country-item" v-for="(item2,index2) in area_code_list" :key="index2" @click="changePhoneCountry(item2,item,index,false)">
                                                    <div class="phone-country-item-num">{{ item2.code }}</div>
                                                    <div class="phone-country-item-name">{{ item2.text }}</div>

                                                </div>
                                            </van-dropdown-item>
                                        </van-dropdown-menu>
                                        <van-field
                                          autocomplete="off"
                                          v-model="item.value"
                                          placeholder="输入WhatsAPP"
                                          clearable
                                        />
                                    </div>
                                    <div class="info-add-item-del" @click="removeWhatsapp(item.id)">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            邮箱
                            <van-icon name="add" class="add-info" @click="addEmail"/>
                        </div>
                        <div class="contact-info-add-list">
                            <div class="contact-info-add-list-item" v-for="(item,index) in emailTabList" :key="item.id">
                                <div class="contact-info-add-item-label">{{ '邮箱' + (index ? index : '') }}</div>
                                <div class="contact-info-add-item-value" v-if="index === 0">{{ item.value || '-' }}</div>
                                <div class="contact-info-add-item-edit" v-if="index > 0">
                                    <div class="info-edit-form-input info-add-item-name">
                                        <van-field
                                          autocomplete="off" v-model="item.name" placeholder="姓名" clearable/>
                                    </div>
                                    <div class="info-edit-form-input info-edit-form-phone">
                                        <van-field
                                          autocomplete="off"
                                          v-model="item.value"
                                          placeholder="输入邮箱"
                                          clearable
                                        />
                                    </div>
                                    <div class="info-add-item-del" @click="removeEmail(item.id)">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-info-box-title">
                            QQ
                            <van-icon name="add" class="add-info" @click="addQQ"/>
                        </div>
                        <div class="contact-info-add-list">
                            <div class="contact-info-add-list-item" v-for="(item,index) in qqTabList" :key="item.id">
                                <div class="contact-info-add-item-label">{{ 'QQ' + (index ? index : '') }}</div>
                                <div class="contact-info-add-item-value" v-if="index === 0">{{ item.value || '-' }}</div>
                                <div class="contact-info-add-item-edit" v-if="index > 0">
                                    <div class="info-edit-form-input info-add-item-name">
                                        <van-field
                                          autocomplete="off" v-model="item.name" placeholder="姓名" clearable/>
                                    </div>
                                    <div class="info-edit-form-input info-edit-form-phone">
                                        <van-field
                                          autocomplete="off"
                                          v-model="item.value"
                                          placeholder="输入QQ"
                                          clearable
                                        />
                                    </div>
                                    <div class="info-add-item-del" @click="removeQQ(item.id)">删除</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </template>


        </div>
        <div v-if="!info_load" class="footer-box">
            <img class="to-top" src="@/assets/to-top.svg" @click="toTop"/>
            <van-button v-if="!editState" class="footer-btn footer-btn2" type="default" @click="toEdit()">编辑</van-button>
            <van-button v-if="editState" class="footer-btn" type="default" @click="exitEdit()" :disabled="save_load">取消</van-button>
            <van-button v-if="editState" class="footer-btn footer-btn2" type="default" :loading="save_load" loading-text="保存中..." @click="submitInfo()">保存</van-button>
        </div>
        <van-image-preview closeable v-model:show="imgVisable" :images="imgList" :showIndex="false"></van-image-preview>
    </div>

</template>

<script>
import {
    getEducation,
    getNumberCountryList,
    getStarDown,
    signedDataJava,
    callOutboundCallPhone,
    callDemo,
    callDown,
    getUserId,
    getCustomerInfo,
    getCustomerAddress,
    invalidReasonData,
    saveCustomerDetail,
    getCustomerSpouse,
} from "@/api/binging";
import {
    Notify,
    Dialog,
    ImagePreview,
    Toast,
} from "vant";
import moment from "moment";
export default {
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component
    },
    data () {
        return {
            info_load: false,
            customer_id: '',
            customer_info: {
                contact: {},
                info: {},
                marriage: {},
            },
            editState: false,
            tab_list: [
                {
                    id: 1,
                    title: '基本信息',
                },
                {
                    id: 2,
                    title: '联系方式',
                },
            ],
            tab_index: 1,
            education_value: '',
            sub_education_value: '',
            education_list: [],
            education_show: false,
            sub_education_show: false,
            area_code_list:[],
            save_load: false,
            sex_show: false,
            sex_value: '',
            sex_list: [
                {
                    text: '未知',
                    value: 0,
                },
                {
                    text: '男',
                    value: 1,
                },
                {
                    text: '女',
                    value: 2,
                },
            ],

            province_list: [],
            province_show: false,
            province_set: {
                text: 'name',
                value: 'id',
                children: 'children',
            },
            province_name: '',
            province_value: '',
            grade_value: '',
            grade_list: [ // 客户等级类型
                {
                    value: 1,
                    label: 'A级',
                    title: '已签约的资源自动转成A级',
                    text: '<div class="grade-label"><div class="grade-title">A级</div><div class="grade-msg">已签约的资源自动转成A级</div></div>',
                    disabled: true
                },
                {
                    value: 2,
                    label: 'B级',
                    title: '客户条件符合申请、预算符合，跟我司签约意向强，14天内可以签约',
                    text: '<div class="grade-label"><div class="grade-title">B级</div><div class="grade-msg">客户条件符合申请、预算符合，跟我司签约意向强，14天内可以签约</div></div>',
                },
                {
                    value: 3,
                    label: 'C级',
                    title: '客户条件符合申请、预算符合，但跟我司签约意向不明确，短期内不确定是否能签约',
                    text: '<div class="grade-label"><div class="grade-title">C级</div><div class="grade-msg">客户条件符合申请、预算符合，但跟我司签约意向不明确，短期内不确定是否能签约</div></div>',
                },
                {
                    value: 4,
                    label: 'D级',
                    title: '客户条件未知，预算未知，跟我司签约意向不明确，短期内不确定是否能签约',
                    text: '<div class="grade-label"><div class="grade-title">D级</div><div class="grade-msg">客户条件未知，预算未知，跟我司签约意向不明确，短期内不确定是否能签约</div></div>',
                },
                {
                    value: 5,
                    label: 'E级',
                    title: '客户咨询我司项目',
                    red_title1: '但条件不符合',
                    text: '<div class="grade-label"><div class="grade-title">E级</div><div class="grade-msg">客户咨询我司项目<span>但条件不符合</span></div></div>',
                },
                {
                    value: 6,
                    label: '无效',
                    title: '咨询非我司业务',
                    red_title1: '且不可导向',
                    title2: '，或连续',
                    red_title2: '三',
                    title3: '个工作日电话不通，或添加微信',
                    red_title3: '5',
                    title4: '天未通过',
                    text: '<div class="grade-label"><div class="grade-title">无效</div><div class="grade-msg">咨询非我司业务<span>且不可导向</span>，或连续<span>三</span>个工作日电话不通，或添加微信<span>5</span>天未通过</div></div>',
                },
                {
                    value: 7,
                    label: '未分级',
                    title: '',
                    text: '<div class="grade-label"><div class="grade-title">未分级</div></div>',
                },
            ],
            grade_show: false,
            other_status: '',

            invalid_arr: [],
            invalid_value: '',
            invalid_show: false,

            star_edit_show: true,
            starStatus: '',
            starCheckList: [],
            star_out_list: [],
            star_show: false,
            company_build_show: false,
            company_build_time: '',
            company_build_value: '',
            company_build_minDate: new Date(1950, 0, 1),

            sign_data: [],
            project_show: false,
            project_value: '',

            phoneTabList:[], // 电话合成数组
            whatTabList:[], // whatsapp合成数组
            wxTabList:[], // 微信合成数组
            emailTabList:[], // 邮箱合成数组
            qqTabList:[], // QQ合成数组

            imgVisable: false,
            imgList: [],

             //外呼参数
            callLoad: false, //外呼按钮loading
            call: false, //外呼按钮状态
            callPhone: '', //主电话拨打按钮禁用
            path: process.env.VUE_WEBSOCKET,
            socket: "",
            Uid: '', //user_id websocket携带参数
            dingState: '',// 监听返回的客户通话状态  呼叫：calling，振铃：ring,接通：link,挂机：hangup
            timer: '', // 监控socket返回值定时器
            reCallCount: 0, // 重连次数
            confirmAble: false,

            spouse_load: false,


        }
    },

    created () {
        this.customer_id = this.$route.query.customer_id
        // this.customer_id = '174802'
        this.getEducationList()
        this.getInvalidArr()
        this.getProvinceList()
        this.getAreaCodeList()
        this.signedProjectList()
        this.getId() // 获取Uid
        this.getCustomerInfoData(true)

    },
    mounted () {
    },
    watch: {
        'dingState': {
            // 执行方法
            handler(newValue, oldVal) {
                if (newValue === "hangup") {
                    if (this.call) {
                        this.callLoad = true
                        Toast('已挂断');

                        //主号码呼叫状态切换
                        this.call = false
                        this.phoneTabList.forEach(i=>i.call = false)
                        // 清空socket返回值
                        this.dingState = ''
                        // 销毁监听
                        this.socket.close()
                        // 主号码防抖关闭
                        this.callLoad = false

                    }
                }
            },
            immediate: true  // 第一次改变就执行
        }
    },

    methods: {
        spouseData(){
            let para={
                id:this.customer_info.marriage.id
            }

            if (para.id.trim() === 0) {
                Notify({type: 'warning', message: '请输入配偶序号'})
                return
            }
            this.spouse_load=true
            getCustomerSpouse(para).then(data => {
                if (data.code === 200) {
                    if (!data.data) {
                        this.spouse_load=false
                        Notify({type: 'warning', message: '未在系统内找到信息，请手动录入信息'})
                        return false
                    }


                    if (data.data.data) {
                        // 判断区号
                        data.data.data.number_country_id = data.data.data.number_country_id ? data.data.data.number_country_id:1
                        this.customer_info.marriage = data.data.data // 渲染配偶表单
                        this.sub_education_value = this.education_list.find(i=>i.value == this.customer_info.marriage.education)?.text
                        this.customer_info.marriage.third_party_country_status = this.customer_info.marriage.third_party_country_status ?? 0
                        this.customer_info.marriage.green_card_status = this.customer_info.marriage.green_card_status ?? 2
                        this.spouse_load = false
                        Dialog.alert({
                            message: data.data.info,
                            confirmButtonColor: '#419EFF',
                        }).then(() => {
                            // on close
                        });
                    } else {
                        this.spouse_load=false
                    }
                } else {
                    this.spouse_load=false
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                this.spouse_load=false
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },


        getCustomerInfoData(type){
            let para = {
                id: this.customer_id,
                group_leader: '',
            }
            if (type){
                this.info_load = true
            }

            this.$showLoad(true)
            getCustomerInfo(para).then(res=>{
                if (res.code === 200){
                    this.reviseData(res)
                    if (res.data.info.marriage === 1 && res.data.marriage) { // 有配偶 生日赋默认值
                        res.data.marriage.birthday = res.data.marriage.birthday || null
                        res.data.marriage.number_country_id = res.data.marriage.number_country_id || 1
                        res.data.marriage.id = res.data.marriage.id || ''
                        res.data.marriage.third_party_country_status = res.data.marriage.third_party_country_status || 0
                        res.data.marriage.green_card_status = res.data.marriage.green_card_status || 2
                    } else {
                        res.data.marriage = {
                            "id": '',
                            "birthday": null,
                            "capital_preparation": '',
                            "condition": '',
                            "education": '',
                            "green_card": "",
                            "green_card_status": 2,
                            "language_ability": "",
                            "speciality": "",
                            "third_party_country": "",
                            "third_party_country_status": 0,
                            "user_chat": "",
                            "user_email": "",
                            "user_name": "",
                            "user_phone": "",
                            "user_qq": "",
                            "work": "",
                            "work_life": "",
                            "number_country_id": 1,
                        }

                    }
                    this.customer_info = res.data
                    this.sex_value = this.sex_list.find(i=>i.value == this.customer_info.info.user_sex)?.text
                    this.education_value = this.education_list.find(i=>i.value == this.customer_info.info.education)?.text
                    this.project_value = this.sign_data.find(i=>i.id == this.customer_info.info.intention_project)?.text
                    this.grade_value = this.grade_list.find(i=>i.value == this.customer_info.info.customer_grade)?.label
                    this.invalid_value = this.invalid_arr.find(i=>i.id == this.customer_info.info.invalid_reason)?.text
                    this.sub_education_value = this.education_list.find(i=>i.value == this.customer_info.marriage.education)?.text
                    // 护照绿卡数据处理
                    this.customer_info.info.third_party_country_status = this.customer_info.info.third_party_country_status ?? 0
                    this.customer_info.info.green_card_status = this.customer_info.info.green_card_status ?? 2
                    // 配偶
                    if (this.customer_info.info.marriage == 1){
                        this.customer_info.marriage.third_party_country_status = this.customer_info.marriage.third_party_country_status ?? 0
                        this.customer_info.marriage.green_card_status = this.customer_info.marriage.green_card_status ?? 2
                    }

                    // 地址级联赋值
                    this.province_name = this.customer_info.info.province_m || ''
                    this.province_value = this.customer_info.info.province ? this.customer_info.info.province[this.customer_info.info.province.length - 1] : ''

                    // 挂星状态赋值
                    this.starStatus = JSON.parse(JSON.stringify(this.customer_info.info.is_start))
                    this.customer_info.info.founding_time = this.formatDate2(this.customer_info.info.founding_time)
                    // 公司成立时间赋值
                    this.company_build_time = this.customer_info.info.founding_time ? new Date(this.customer_info.info.founding_time) : new Date()
                    this.company_build_value = this.customer_info.info.founding_time ? this.formatDate3(this.company_build_time) : ''
                    this.$showLoad()
                    this.info_load = false
                    this.editState = false
                    this.star_show = false

                }else {
                    this.$showLoad()
                    this.info_load = false
                    Notify({type: 'danger', message: res.message})
                }
            }).catch((err) => {
                console.log(err)
                this.$showLoad()
                this.info_load = false
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },

        // 删除微信
        removeWx(e){
            Dialog.confirm({
                message: '是否删除本条数据?',
                confirmButtonColor: '#419EFF',
            })
              .then(() => {
                  this.wxTabList = this.wxTabList.filter(tab => tab.id !== e);
                  Toast.success('删除成功');
              })
              .catch(() => {
                  Toast('已取消删除');
              });
        },
        // 微信校验
        testWx(e){
            if (e.length > 0){
                var judge_wechat = /[\u4e00-\u9fa5]/; // 微信正则判断
                // let judge_wechat = /^[_a-zA-Z]{1}[-_a-zA-Z0-9]{5,31}$/; // 微信正则判断
                for(let i = 0; i < e.length; i++){
                    if (e[i].name.trim() === ''){
                        Notify({type: 'warning', message: '请输入微信' + (i + 1) + '姓名后继续操作'})
                        return false
                    }
                    if (!e[i].value || e[i].value.trim() === ''){
                        Notify({type: 'warning', message: '请输入微信' + (i + 1) + '后继续操作'})
                        return false
                    }
                    if (judge_wechat.test(e[i].value)) {
                        Notify({type: 'warning', message: '微信' + (i + 1) + '不符合规则，请完善后继续操作'})
                        return false
                    }
                }
                return true
            }
        },
        // 添加微信
        addWx(){
            if (!this.testWx(this.wxTabList.slice(1)) && this.wxTabList.slice(1).length > 0) {
                return
            }
            this.wxTabList.push({
                id: this.wxTabList[this.wxTabList.length - 1].id + 1,
                name: '',
                value: '',
            })
            this.wxSelTab = this.wxTabList[this.wxTabList.length - 1].id // 切换至新添加的tab
        },

        // 删除QQ
        removeQQ(e){
            Dialog.confirm({
                message: '是否删除本条数据?',
                confirmButtonColor: '#419EFF',
            })
              .then(() => {
                  this.qqTabList = this.qqTabList.filter(tab => tab.id !== e);
                  Toast.success('删除成功');
              })
              .catch(() => {
                  Toast('已取消删除');
              });
        },
        // QQ校验
        testQQ(e){
            if (e.length > 0){
                var judge_QQ = /^[1-9]\d{4,9}$/; // qq正则判断
                for (let i = 0; i < e.length; i++) {
                    if (e[i].name.trim() === ''){
                        Notify({type: 'warning', message: '请输入QQ' + (i + 1) + '姓名后继续操作'})
                        return false
                    }
                    if (!e[i].value || e[i].value.trim() === ''){
                        Notify({type: 'warning', message: '请输入QQ' + (i + 1) + '后继续操作'})
                        return false
                    }
                    if (!judge_QQ.test(e[i].value)) {
                        Notify({type: 'warning', message: 'QQ' + (i + 1) + '不符合规则，请完善后继续操作'})
                        return false
                    }
                }
                return true
            }
        },
        // 添加QQ
        addQQ(){
            if (!this.testQQ(this.qqTabList.slice(1)) && this.qqTabList.slice(1).length > 0) {
                return
            }
            this.qqTabList.push({
                id: this.qqTabList[this.qqTabList.length - 1].id + 1,
                name: '',
                value: '',
            })
        },

        // 删除邮箱
        removeEmail(e){
            Dialog.confirm({
                message: '是否删除本条数据?',
                confirmButtonColor: '#419EFF',
            })
              .then(() => {
                  this.emailTabList = this.emailTabList.filter(tab => tab.id !== e);
                  Toast.success('删除成功');
              })
              .catch(() => {
                  Toast('已取消删除');
              });
        },
        // 邮箱校验
        testEmail(e){
            if (e.length > 0){
                var judge_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
                for (let i = 0; i < e.length; i++) {
                    if (e[i].name.trim() === ''){
                        Notify({type: 'warning', message: '请输入邮箱' + (i + 1) + '姓名后继续操作'})
                        return false
                    }
                    if (!e[i].value || e[i].value.trim() === ''){
                        Notify({type: 'warning', message: '请输入邮箱' + (i + 1) + '后继续操作'})
                        return false
                    }
                    if (!judge_email.test(e[i].value)) {
                        Notify({type: 'warning', message: '邮箱' + (i + 1) + '不符合规则，请完善后继续操作'})
                        return false
                    }
                }
                return true
            }
        },
        // 添加邮箱
        addEmail(){
            // if (!this.testEmail(this.emailTabList.slice(1)) && this.emailTabList.slice(1).length > 0) {
            //     return
            // }
            this.emailTabList.push({
                id: this.emailTabList[this.emailTabList.length - 1].id + 1,
                name: '',
                value: '',
            })
        },

        // WhatsApp删除
        removeWhatsapp(e){
            Dialog.confirm({
                message: '是否删除本条数据?',
                confirmButtonColor: '#419EFF',
            })
              .then(() => {
                  this.whatTabList = this.whatTabList.filter(tab => tab.id !== e);
                  Toast.success('删除成功');
              })
              .catch(() => {
                  Toast('已取消删除');
              });
        },
        // WhatsApp校验
        testWhatsApp(e){
            if (e.length > 0){
                for(let i = 0; i < e.length; i++){
                    if (e[i].name.trim() === ''){
                        Notify({type: 'warning', message: '请输入WhatsApp' + (i + 1) + '姓名后继续操作'})
                        return false
                    }
                    if (!e[i].value || e[i].value.trim() === ''){
                        Notify({type: 'warning', message: '请输入WhatsApp' + (i + 1) + '后继续操作'})
                        return false
                    }
                    if (!this.rulePhone(e[i].value,e[i].nc_id)){
                        Notify({type: 'warning', message: 'WhatsApp' + (i + 1) + '不符合规则，请完善后继续操作'})
                        return false
                    }
                }
                return true
            }
        },
        // 添加WhatsApp
        addWhatsApp() {
            this.whatTabList.push({
                id: this.whatTabList[this.whatTabList.length - 1].id + 1,
                name: '',
                nc_id: 1,
                value: '',
            })
        },

        // 获取区号和校验规则
        getCodeRule(e){
            let codeInfo = {}
            this.area_code_list.forEach(item=>{
                if (e === item.value){
                    codeInfo = item
                }
            })
            return codeInfo
        },

        // 校验电话
        rulePhone(phoneNum,areaId,e){
            let newObject = this.getCodeRule(areaId)
            if (newObject.check_rule){
                let newRule = new RegExp(this.getCodeRule(areaId).check_rule.replace(/\\\\d/g,'\\d'))
                if (!newRule.test(this.getCodeRule(areaId).code.slice(1) + phoneNum)){
                    if (e){
                        Notify({type: 'warning', message: '手机号不符合规则'})
                    }
                    return false
                }else {
                    return true
                }
            }else {
                if (e){
                    Notify({type: 'warning', message: '请选择国家区号'})
                }
                return false
            }
        },
        // 删除电话
        removePhone(e){
            Dialog.confirm({
                // title: '标题',
                message: '是否删除本条数据?',
                confirmButtonColor: '#419EFF',
            })
              .then(() => {
                  this.phoneTabList = this.phoneTabList.filter(tab => tab.id !== e);
                  Toast.success('删除成功');
              })
              .catch(() => {
                  Toast('已取消删除');
              });
        },
        // 电话校验
        testPhone(e){
            if (e.length > 0){
                for(let i = 0; i < e.length; i++){
                    if (e[i].name.trim() === ''){
                        Notify({type: 'warning', message: '请输入电话' + (i + 1) + '姓名后继续操作'})
                        return false
                    }
                    if (!e[i].value || e[i].value.trim() === ''){
                        Notify({type: 'warning', message: '请输入电话' + (i + 1) + '电话后继续操作'})
                        return false
                    }
                    if (!this.rulePhone(e[i].value,e[i].nc_id)){
                        Notify({type: 'warning', message: '电话' + (i + 1) + '电话不符合规则，请完善后继续操作'})
                        return false
                    }
                }
                return true
            }
        },
        // 添加电话
        addPhone() {
            this.phoneTabList.push({
                id: this.phoneTabList[this.phoneTabList.length - 1].id + 1,
                name: '',
                nc_id: 1,
                value: '',
                call: false,
            })
        },

        // 二维码弹窗
        openQr(e){
            this.imgVisable = true
            if (e.wx_type == 0){
                this.imgList =[ e.adviser_path.code_path]
            }else if (e.wx_type == 1){
                this.imgList = [e.qrUrl]
            }
        },

         // websocket创建实例
        init: function () {
            this.dingState = ''
            if (typeof (WebSocket) === "undefined") {
                alert("您的设备不支持socket")
            } else {
                // 实例化socket
                this.socket = new WebSocket(process.env.VUE_APP_WEBSOCKET + this.Uid)
                // 监听socket连接
                this.socket.onopen = this.open
                // 监听socket错误信息
                this.socket.onerror = this.error
                // 监听socket消息
                this.socket.onmessage = this.getMessage
                // 销毁监听
                this.socket.onclose = this.close
            }
        },
        open: function () {
            console.log("socket连接成功")
        },
        error: function (data) {
            console.log(data)
        },
        getMessage: function (msg) {
            console.log(msg,'接受参数')
            this.dingState = msg.data
            console.log('拨打状态：' + this.dingState)
        },
        // 发送消息给被连接的服务端
        send: function (params) {
            this.socket.send(params)
        },
        close: function () {
            console.log(this.call,'拨打状态')
            if (this.call) {
                this.reCallCount++
                if (this.reCallCount < 4) {
                    console.log("socket异常关闭，第" + this.reCallCount + "次重连中...")
                    let that = this
                    setTimeout(function () {
                        that.init()
                    }, 2000)
                    return
                } else {
                    console.log("socket重连已超过3次，socket已关闭")
                    Dialog.alert({
                        message: '网络异常，请在通话结束后点击按钮,手动挂断电话',
                        confirmButtonText: '我知道了',
                        confirmButtonColor: '#419EFF',
                    }).then(() => {
                        // on close
                    });
                }
            }
            console.log("socket已关闭")
        },

        //获取user_id
        getId() {
            getUserId().then(data => {
                if (data.code === 200) {
                    this.Uid = data.data.uId
                } else {
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },

        //判断此电话当日拨打次数
        callOutPro(item,type) {
            if (((!item.call && !this.call) || (item.call && this.call)) && !this.callLoad){
                let para = {
                    customer_id: this.customer_id,
                    phone: type ? item.value : '',
                }

                callOutboundCallPhone(para).then(data => {
                    if (data.code === 200) {
                        if (!data.data) {
                            this.callOut(item,type)
                        } else if (data.data.status === 2) {
                            if (!this.call) {
                                Dialog.confirm({
                                    message: data.data.message,
                                    confirmButtonColor: '#419EFF',
                                })
                                  .then(() => {
                                      this.callOut(item,type)
                                  })
                                  .catch(() => {
                                      Toast('已取消呼叫');
                                  });
                            } else {
                                this.callOut(item,type)
                            }
                        }
                    } else {
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            }
            else {
                console.log('不切换')
            }


        },


        // 主号码外呼/挂断
        callOut(item,type) {
            if (!this.call) {
                // 清空socket状态值
                this.dingState = ''
                // 呼出接口
                Dialog.confirm({
                    message: '此操作将呼叫该客户, 是否继续?(外呼时请勿刷新及关闭当前页面)',
                    confirmButtonColor: '#419EFF',
                })
                  .then(() => {
                      let para = {
                          customer_id: this.customer_id,
                          phone: type ? item.value : '',
                      }
                      // 主号码防抖开启
                      this.callLoad = true

                      //主号码外呼（开始）
                      callDemo(para).then(data => {
                          if (data.code === 200) {
                              // 重连次数回调
                              this.reCallCount = 0
                              //连接socket
                              this.init()
                              // 主号码防抖关闭
                              this.callLoad = false
                              // 切换号码呼叫状态
                              this.call = true
                              item.call = true
                          } else {
                              // 主号码防抖关闭
                              this.callLoad = false
                              Notify({type: 'danger', message: data.message})
                          }
                      }).catch(() => {
                          this.callLoad = false
                          Notify({type: 'warning', message: '服务器连接失败'})
                      })
                      //主号码外呼（结束）

                      // // 模拟主号码呼叫（开始）
                      // let that = this
                      // setTimeout(function () {
                      //     // 重连次数回调
                      //     that.reCallCount = 0
                      //     //连接socket
                      //     that.init()
                      //     // 主号码防抖关闭
                      //     that.callLoad = false
                      //     // 切换号码呼叫状态
                      //     that.call = true
                      //     item.call = true
                      //     console.log('客户电话:' + item.value, '客户序号:' + that.customer_id, '呼出')
                      // }, 2000)
                      // // 模拟主号码呼叫（结束）
                  })
                  .catch(() => {
                      Toast('已取消呼叫');
                  });
            } else {
                // 挂断接口
                Dialog.confirm({
                    message: '此操作将断开外呼, 是否继续?',
                    confirmButtonColor: '#419EFF',
                })
                  .then(() => {
                      // 主号码防抖开启
                      this.callLoad = true

                      // 主号码挂断（开始）
                      callDown().then(data => {
                          if (data.code === 200) {
                              // 主号码防抖关闭
                              this.callLoad = false
                              Toast('已挂断');
                              this.call = false
                              this.phoneTabList.forEach(i=>i.call = false)
                              //监听挂断返回值
                              let that = this
                              that.timer = setInterval(function () {
                                  if (that.dingState === "hangup") {
                                      // 销毁监听
                                      that.socket.close()
                                      that.dingState = ''
                                      //情况定时器
                                      that.timer = ''
                                  }
                              }, 100)
                          } else {
                              // 主号码防抖关闭
                              this.callLoad = false
                              Notify({type: 'danger', message: data.message})
                          }
                      })
                      // 主号码挂断（结束）

                      // // 模拟主号码挂断（开始）
                      // let that = this
                      // setTimeout(function () {
                      //     // 主号码防抖关闭
                      //     that.callLoad = false
                      //     Toast('已挂断');
                      //     that.call = false
                      //     that.phoneTabList.forEach(i=>i.call = false)
                      //     // 销毁监听
                      //     that.socket.close()
                      //     that.dingState = ''
                      // }, 2000)
                      // // 模拟主号码挂断（结束）
                  })
                  .catch(() => {
                      Toast('已取消挂断');
                      return false
                  });
            }
        },


        // 数据修正
        reviseData(data){
            // 附加号码数据修正
            for (let i = 0; i < data.data.contact.dynamicPhones.length; i++) {
                data.data.contact.dynamicPhones[i].nc_id = data.data.contact.dynamicPhones[i].nc_id == '' || data.data.contact.dynamicPhones[i].nc_id == null ? 1 : data.data.contact.dynamicPhones[i].nc_id
                data.data.contact.dynamicPhones[i].id = i + 2 // tab唯一标识
                data.data.contact.dynamicPhones[i].call = false // 气泡显示
            }
            this.phoneTabList=[ // 主联系方式与附属联系方式拼接
                {
                    name:data.data.info.user_name,
                    id:1,
                    nc_id:data.data.info.number_country_id,
                    value:data.data.info.user_phone,
                    call: false,
                }
            ].concat(data.data.contact.dynamicPhones)

            // 附加whatsApp号码数据修正
            if(data.data.contact.dynamicWhatsapps){
                for (let i = 0; i < data.data.contact.dynamicWhatsapps.length; i++) {
                    data.data.contact.dynamicWhatsapps[i].nc_id = data.data.contact.dynamicWhatsapps[i].nc_id == '' || data.data.contact.dynamicWhatsapps[i].nc_id == null ? 1 : data.data.contact.dynamicWhatsapps[i].nc_id
                    data.data.contact.dynamicWhatsapps[i].id = i + 2 // tab唯一标识
                }
                this.whatTabList=[ // 主联系方式与附属联系方式拼接
                    {
                        name:data.data.info.user_name,
                        id:1,
                        nc_id:data.data.info.whatsapp_number,
                        value:data.data.info.user_whatsapp,
                    }
                ].concat(data.data.contact.dynamicWhatsapps)
            }else{
                this.whatTabList=[ // 主联系方式与附属联系方式拼接
                    {
                        name:data.data.info.user_name,
                        id:1,
                        nc_id:data.data.info.whatsapp_number,
                        value:data.data.info.user_whatsapp,
                    }
                ]
            }
            // 附加微信数据修正
            for (let i = 0; i < data.data.contact.dynamicWeChats.length; i++) {
                data.data.contact.dynamicWeChats[i].id = i + 2 // tab唯一标识
            }

            this.wxTabList = [  // 主联系方式与附属联系方式拼接
                {
                    name:data.data.info.user_name,
                    id:1,
                    wx_type:data.data.info.wx_type , // 客户微信添加方式
                    value:data.data.info.user_chat,
                    adviser_name:data.data.info.service_name, // 客服名称
                    qrUrl:data.data.info.image_path, // 客户二维码
                    adviser_path:data.data.info.url, // 客服二维码
                    tooltip:false
                }
            ].concat(data.data.contact.dynamicWeChats)

            // 附加邮箱数据修正
            for (let i = 0; i < data.data.contact.dynamicEmails.length; i++) {
                data.data.contact.dynamicEmails[i].id = i + 2 // tab唯一标识
            }

            this.emailTabList = [
                {
                    name:data.data.info.user_name,
                    id:1,
                    value:data.data.info.user_email,
                    tooltip:false
                }
            ].concat(data.data.contact.dynamicEmails)

            // 附加QQ数据修正
            for (let i = 0; i < data.data.contact.dynamicQQs.length; i++) {
                data.data.contact.dynamicQQs[i].id = i + 2 // tab唯一标识
                data.data.contact.dynamicQQs[i].tooltip = false // 气泡显示
                data.data.contact.dynamicQQs[i].editAble = false  // 编辑状态为否
                data.data.contact.dynamicQQs[i].newQQ = false // 是否为新增未保存状态
            }

            this.qqTabList = [
                {
                    name:data.data.info.user_name,
                    id:1,
                    value:data.data.info.user_qq,
                    tooltip:false
                }
            ].concat(data.data.contact.dynamicQQs)
        },

        toEdit(){
            if (this.call){
                Notify({type: 'warning', message: '请先结束外呼后继续操作'})
                return
            }
            this.editState = true
            document.getElementById('info-box-out').scrollTo(0,0)
        },

        toTop(){
            document.getElementById('info-box-out').scrollTo(0,0)
        },

        // 取消
        exitEdit(){
            // this.editState = false
            document.getElementById('info-box-out').scrollTo(0,0)
            this.getCustomerInfoData(true)
        },

        findIndex(data,list,type){
            if (type === 1){
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id == data){
                        return i
                    }
                }
                return 0
            }
            else if (type === 2){
                for (let i = 0; i < list.length; i++) {
                    if (list[i].value == data){
                        return i
                    }
                }
                return 0
            }

        },

        submitNextInfo(para,type){
            this.save_load = true
            para.customerForm.birthday = para.customerForm.birthday ? para.customerForm.birthday : null
            saveCustomerDetail(para).then(data => {
                if (data.code === 200) {
                    Toast.loading({
                        duration: 0,
                        message: '保存成功',
                        forbidClick: true,
                    });
                    this.save_load = false
                    setTimeout(()=>{
                        this.editState = false
                        if (type){
                            this.$router.replace(
                              {
                                  path: '/singleSignOn',
                                  query: {
                                      type: '1',
                                  }
                              }
                            )
                        }
                        else {
                            this.getCustomerInfoData(true)
                        }
                    },1000)

                } else {
                    this.save_load = false
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                this.save_load = false
                Notify({type: 'warning', message: '服务器连接失败'})
            })

        },


        // 提交详情
        submitInfo(){
          if (this.tab_index == 1){
                this.customer_info.info.dynamicPhone =  this.customer_info.contact.dynamicPhones
                this.customer_info.info.dynamicWhatsapps =  this.customer_info.contact.dynamicWhatsapps
                this.customer_info.info.dynamicWeChat =  this.customer_info.contact.dynamicWeChats
                this.customer_info.info.dynamicEmail =  this.customer_info.contact.dynamicEmails
                this.customer_info.info.dynamicQQ =  this.customer_info.contact.dynamicQQs

                if (this.customer_info.info.user_name.trim() === ''){
                    Notify({type: 'warning', message: '请输入客户名称'})
                    return;
                }
                if (this.customer_info.info.user_name.trim().length > 60){
                    Notify({type: 'warning', message: '客户名称最多可输入60个字符'})
                    return
                }

                if (this.customer_info.info.birthday && +this.customer_info.info.birthday.trim() > 200) {
                    Notify({type: 'warning', message: '年龄不能超过200'})
                    return false
                }

                if (this.customer_info.info.customer_grade == 5 && this.customer_info.info.e_grade_reason.trim() === '' ){
                    Notify({type: 'warning', message: '客户等级E级，需要填写评级原因'})
                    return false
                }

                if (this.customer_info.info.customer_grade == 6 && !this.customer_info.info.invalid_reason){
                    Notify({type: 'warning', message: '客户等级无效，需要选择无效原因'})
                    return false
                }

                if (this.customer_info.info.customer_grade == 6 && this.other_status == 1 && !this.customer_info.info.invalid_reason_content){
                    Notify({type: 'warning', message: '该无效原因，请输入具体情况说明'})
                    return false
                }

                if (this.customer_info.info.marriage == 1){
                    // 配偶电话校验
                    if (this.customer_info.marriage.user_phone && !this.rulePhone(this.customer_info.marriage.user_phone, this.customer_info.marriage.number_country_id)) {
                        Notify({type: 'warning', message: '配偶电话不符合规则'})
                        return;
                    }
                    if (this.customer_info.marriage.birthday && +this.customer_info.marriage.birthday.trim() > 200) {
                        Notify({type: 'warning', message: '配偶年龄不能超过200'})
                        return false
                    }
                }

                if (this.customer_info.info.third_party_country_status == 1) {
                    if (this.customer_info.info.third_party_country.trim() === ''){
                        Notify({type: 'warning', message: '请输入其他国家护照详情'})
                        return;
                    }
                }
                else {
                    this.customer_info.info.third_party_country = ''
                }

                if (this.customer_info.info.green_card_status == 1) {
                    if (this.customer_info.info.green_card.trim() === ''){
                        Notify({type: 'warning', message: '请输入其他国家绿卡详情'})
                        return;
                    }
                }
                else {
                    this.customer_info.info.green_card = ''
                }
                // 校验星标必填项
                if (this.customer_info.info.is_start == 1){
                    if (!this.customer_info.info.user_sex && this.customer_info.info.user_sex !== 0){
                        Notify({type: 'warning', message: '请选择性别'})
                        return
                    }
                    if (!this.customer_info.info.birthday || this.customer_info.info.birthday.trim() === ''){
                        Notify({type: 'warning', message: '请输入年龄'})
                        return
                    }
                    if (!this.customer_info.info.education && this.customer_info.info.education !== 0){
                        Notify({type: 'warning', message: '请选择学历'})
                        return
                    }
                    if (!this.customer_info.info.work || this.customer_info.info.work.trim() === ''){
                        Notify({type: 'warning', message: '请输入职业信息'})
                        return
                    }
                    // if (this.customer_info.info.intention_project == '' || this.customer_info.info.intention_project == null) {
                    //     Notify({type: 'warning', message: '请选择意向签约项目'})
                    //     return
                    // }
                    if (this.customer_info.info.province == null || this.customer_info.info.province.length === 0){
                        Notify({type: 'warning', message: '请选择客户地址'})
                        return
                    }
                    if (!this.customer_info.info.customer_grade && this.customer_info.info.customer_grade !== 0){
                        Notify({type: 'warning', message: '请选择客户等级'})
                        return
                    }

                    if (this.customer_info.info.customer_grade > 3){
                        Notify({type: 'warning', message: '星标客户等级需划分在C级及以上'})
                        return
                    }
                    if (!this.customer_info.info.intentional_state || this.customer_info.info.intentional_state.trim() === ''){
                        Notify({type: 'warning', message: '请输入意向国家'})
                        return
                    }
                }
                // 摘星原因赋值
                if (this.starStatus == 1 && this.customer_info.info.is_start == 0) {
                    let reasaonData = this.star_out_list.filter(i=>this.starCheckList.includes(i.id))
                    if(reasaonData.length === 0) {
                        Notify({type: 'warning', message: '请选择摘星原因'})
                        return
                    }

                    if (reasaonData.some(i=>i.content.trim() === '')) {
                        Notify({type: 'warning', message: '摘星需输入具体原因'})
                        return
                    }
                    this.customer_info.info.reason = reasaonData
                }

                // 等级为BCD的客户
                if ([2,3,4].includes(this.customer_info.info.customer_grade)){
                    if (this.customer_info.info.is_company == 1){
                        if (this.customer_info.info.scale.trim() === ''){
                            Notify({type: 'warning', message: '请输入企业规模'})
                            return
                        }
                        if (this.customer_info.info.business.trim() === ''){
                            Notify({type: 'warning', message: '请输入主营业务'})
                            return
                        }
                        if (!this.customer_info.info.founding_time){
                            Notify({type: 'warning', message: '请选择企业成立时间'})
                            return
                        }
                    }

                    if (this.customer_info.info.marriage == 1){
                        if (!this.customer_info.marriage.user_name || this.customer_info.marriage.user_name.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶名称'})
                            return
                        }
                        if (this.customer_info.marriage.birthday.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶年龄'})
                            return
                        }
                        if (!this.customer_info.marriage.user_phone || this.customer_info.marriage.user_phone.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶电话'})
                            return
                        }
                        if (!this.customer_info.marriage.user_chat || this.customer_info.marriage.user_chat.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶微信'})
                            return
                        }
                        if (!this.customer_info.marriage.education){
                            Notify({type: 'warning', message: '请选择配偶学历'})
                            return
                        }
                        if (!this.customer_info.marriage.speciality || this.customer_info.marriage.speciality.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶专业'})
                            return
                        }
                        if (!this.customer_info.marriage.work || this.customer_info.marriage.work.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶职业'})
                            return
                        }
                        if (!this.customer_info.marriage.work_life || this.customer_info.marriage.work_life.trim() === ''){
                            Notify({type: 'warning', message: '请输入配偶工作年限'})
                            return
                        }
                    }
                }
            }
            else {
                // 校验电话
                if (this.phoneTabList.slice(1).length > 0){
                    if (!this.testPhone(this.phoneTabList.slice(1))){
                        return
                    }
                }
                // 校验whatsApp
                if (this.whatTabList.slice(1).length > 0){
                    if (!this.testWhatsApp(this.whatTabList.slice(1))){
                        return
                    }
                }
                // 校验微信
                if (this.wxTabList.slice(1).length > 0){
                    if (!this.testWx(this.wxTabList.slice(1))){
                        return
                    }
                }
                // 校验邮箱
                if (this.emailTabList.slice(1).length > 0){
                    if (!this.testEmail(this.emailTabList.slice(1))){
                        return
                    }
                }
                // 校验QQ
                if (this.qqTabList.slice(1).length > 0){
                    if (!this.testQQ(this.qqTabList.slice(1))){
                        return
                    }
                }

                this.customer_info.info.dynamicPhone = this.phoneTabList.slice(1)
                this.customer_info.info.dynamicWhatsapps =  this.whatTabList.slice(1)
                this.customer_info.info.dynamicWeChat =  this.wxTabList.slice(1)
                this.customer_info.info.dynamicEmail =  this.emailTabList.slice(1)
                this.customer_info.info.dynamicQQ = this.qqTabList.slice(1)

            }
            let para = {
                customerForm: this.customer_info.info,
                spouseForm: this.customer_info.marriage
            }
            if (this.customer_info.info.customer_grade == 5 || this.customer_info.info.customer_grade == 6){
                Dialog.confirm({
                    message: '当前客户等级为' + (this.customer_info.info.customer_grade == 5 ? 'E级' : '无效') + '保存后该客户将与您解绑并进入共享池，是否继续？',
                    confirmButtonColor: '#419EFF',
                    confirmButtonText: '继续',
                })
                  .then(() => {
                      this.submitNextInfo(para,true)
                  })
                  .catch(() => {

                  });
            }
            else {
                this.submitNextInfo(para)
            }
        },
        changeMarriage(e){

        },
        changePhoneCountry(e,item,index,type){
            item.nc_id = e.value
            this.$refs[(type ? 'phone' : 'WhatsAPP') + index][0].toggle()
        },

        changeCountryId(e){
            this.customer_info.marriage.number_country_id = e.value
            this.$refs.countryId.toggle()
        },

        subChangeThirdParty(e){
            if (e != 1){
                this.customer_info.marriage.third_party_country = ''
            }
        },

        changeThirdParty(e){
            if (e != 1){
                this.customer_info.info.third_party_country = ''
            }
        },
        subChangeGreenCard(e) {
            if (e != 1) {
                this.customer_info.marriage.green_card = ''
            }
        },

        changeGreenCard(e){
            if (e != 1){
                this.customer_info.info.green_card = ''
            }
        },

        // 公司成立时间
        companyBuildConfirm(e){
            this.customer_info.info.founding_time = this.formatDate3(e)
            this.company_build_value = this.formatDate3(e)
            this.company_build_show = false;
        },

        // 星标企业主情况
        changeCompany(e){
            if (e != 1){
                this.customer_info.info.scale = ''
                this.customer_info.info.business = ''
                this.customer_info.info.founding_time = ''
                this.company_build_time = ''
                this.company_build_value = ''
            }
        },

        // 星标状态切换
        changeStar(e) {
            if (this.starStatus == 1 && e == 0) {
                this.$showLoad(true)
                getStarDown().then(res => {
                    if (res.code === 200) {
                        this.customer_info.info.reason = []
                        for (let i = 0; i < res.data.length; i++) { // 添加辅助变量
                            res.data[i].content = ''
                        }
                        this.star_out_list = res.data
                        this.starCheckList = [] // 清空多选项
                        this.star_show = true
                        this.$showLoad()
                    } else {
                        this.$showLoad()
                        this.customer_info.info.is_start = 1
                        Notify({type: 'danger', message: res.message})
                    }
                }).catch(() => {
                    this.$showLoad()
                    this.customer_info.info.is_start = 1
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            }
            else {
                this.star_show = false
            }
        },

        // 摘星原因选择
        starOutChange(e){
            this.star_out_list.forEach((item,index)=>{
                if (!e.includes(item.id)){
                    item.content = ''
                }
            })
        },

        projectConfirm(e){
            this.customer_info.info.intention_project = e.id;
            this.project_value = e.text
            this.project_show = false;
        },

        invalidConfirm(e){
            this.customer_info.info.invalid_reason = e.id;
            this.invalid_value = e.text
            this.other_status = e.other_status;
            this.invalid_show = false;
        },

        gradeConfirm(e){
            this.customer_info.info.customer_grade = e.value;
            this.grade_value = e.label
            if (this.customer_info.info.customer_grade != 5 && this.customer_info.info.customer_grade != 6){


            }
            this.customer_info.info.invalid_reason_content = ''
            this.customer_info.info.invalid_reason = ''
            this.customer_info.info.e_grade_reason = ''
            this.other_status = ''
            this.invalid_value = ''
            this.grade_show = false;
        },

        provinceFinish({ selectedOptions}){
            this.province_name = selectedOptions.map((option) => option.name).join('/');
            this.customer_info.info.province = selectedOptions.map(i=>i.id)
            this.province_show = false;
        },

        subEducationConfirm(e){
            this.customer_info.marriage.education = e.value;
            this.sub_education_value = e.text;
            this.sub_education_show = false;
        },

        educationConfirm(e){
            this.customer_info.info.education = e.value;
            this.education_value = e.text;
            this.education_show = false;
        },

        sexConfirm(e){
            this.customer_info.info.user_sex = e.value;
            this.sex_value = e.text;
            this.sex_show = false;
        },

        // 获取数组
        getInvalidArr() {
            invalidReasonData().then(data => {
                if (data.code === 200) {
                    this.invalid_arr = data.data.map(i=>{
                        return {
                            text: i.title,
                            id: i.id,
                            other_status: i.other_status
                        }
                    })
                } else {
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },

        // 获取国家区号
        getAreaCodeList(){
            getNumberCountryList().then(res=>{
                if (res.code === 200){
                    // 区号数组调整
                    this.area_code_list = res.data.map(i=>{
                        return {
                            value: i.id,
                            text: i.area_name,
                            check_rule: i.check_rule,
                            code: i.code
                        }
                    })
                }else {
                    Notify({type: 'danger', message: res.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },

        formatDate(time) {
            return time ? moment(time * 1000).format("YYYY-MM-DD HH:mm:ss") : '-'
        },

        formatDate2(time) {
            return time ? moment(time * 1000).format("YYYY-MM-DD") : ''
        },
        formatDate3(time) {
            return time ? moment(time).format("YYYY-MM-DD") : ''
        },

        // 签约项目列表
        signedProjectList() {
            signedDataJava({ page: 1 }).then(data => {
                if (data.code === 200) {
                    this.sign_data = data.data.data.map(i=>{
                        return {
                            text: i.title,
                            id: i.id
                        }
                    })
                    // // 意向签约项目数据处理
                    // this.sign_data.forEach(i=>{
                    //     if (i.children){
                    //         delete i.children
                    //     }
                    // })
                } else {
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },

        // 获取地址
        getProvinceList(){
            getCustomerAddress().then(data => {
                if (data.code === 200) {
                    this.province_list = data.data
                } else {
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },


        // 获取学历
        getEducationList(){
            getEducation().then(res=>{
                if (res.code === 200){
                    this.education_list = res.data.map(i=>{
                        return {
                            text: i.title,
                            value: i.id
                        }
                    })
                }else {
                    Notify({type: 'danger', message: res.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },

        // 客户等级展示
        levelName(row) {
            let levelArr = ['', 'A', 'B', 'C', 'D', 'E', '无效', '未分级']
            return levelArr[row]
        },

        selTab(data){
            if (!this.info_load){
                if (this.editState){
                    Notify({type: 'warning', message: '请先保存当前页面信息后继续操作'})
                    return
                }

                if (this.call){
                    Notify({type: 'warning', message: '请先结束外呼后继续操作'})
                    return
                }

                if (this.tab_index !== data.id) {
                    this.tab_index = data.id;
                }
            }
        },
    },


}
</script>

<style scoped>
.main-body {
    background: #FFFFFF;
    height: 100vh;
    position: relative;
    z-index: 100;
}

.body-load{
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.footer-box{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0px -2px 2px 0px rgba(228,228,228,0.5);
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.footer-btn{
    width: calc((100% - 15px) / 2);
    box-sizing: border-box;
    height: 50px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #E5E5E5;
    color: #333333;
}
.footer-btn.footer-btn2{
    border: none;
    background: #409EFF;
    color: #FFFFFF;
}

.binding-box{
    height: calc(100% - 90px);
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 0;
    display: flex;
    flex-direction: column;
}

.to-top{
    width: 42px;
    height: 42px;
    flex-shrink: 0;
}
.info-tab{
    display: flex;
    justify-content: space-between;
    margin: 0 38px 16px;
    padding: 4px;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
}

.info-tab-item{
    width: calc((100% - 2px) / 2);
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    line-height: 32px;
    text-align: center;
}
.info-tab-item.info-tab-item-active{
    background: #419EFF;
    color: #FFFFFF;
    border-radius: 16px;
}
.info-box-out{
    overflow-y: auto;
    flex: 1;
    padding-bottom: 20px;
}

.info-box-out2{
    padding: 0 10px 20px;
}

.info-box{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(211,211,211,0.4);
    border-radius: 16px;
    border: 1px solid #F3F5F9;
    padding: 20px 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.info-box:last-child{
    margin-bottom: 0;
}

.info-box-title{
    font-weight: 600;
    font-size: 16px;
    color: #272727;
    line-height: 22px;
    word-wrap: break-word;
    word-break: break-all;
    margin-bottom: 20px;
}
.info-box-item{
    display: flex;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.info-box-item:last-child{
    margin-bottom: 0;
}

.info-box-item-title{
    width: 90px;
    color: #6F7379;
}
.info-box-item-title.info-box-item-title2{
    width: 142px;
}

.info-box-item-content{
    color: #272727;
    word-wrap: break-word;
    word-break: break-all;
    flex: 1;
}

.info-box-item-list{
    margin-bottom: 15px;
}
.info-box-item-list:last-child{
    margin-bottom: 0;
}

.info-box-edit-title{
    background: #F5F5FC;
    width: 100%;
    box-sizing: border-box;
    padding: 11px 20px 11px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #272727;
    line-height: 20px;
    margin-bottom: 20px;
}

.info-box-edit-star{
    width: 14px;
    height: 14px;
    flex-shrink: 0;
}

.info-box-edit-star-show{
    transform: rotate(180deg);
}

.info-box-edit-form{
    padding: 0 10px;
}

.info-box-edit-form-item{
    margin-bottom: 16px;
}

.info-box-edit-form-item:last-child{
    margin-bottom: 20px;
}

.info-edit-form-title{
    font-weight: 600;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}
.info-edit-form-title span{
    font-weight: 400;
    font-size: 13px;
    color: #F56C6C;
}

.info-edit-form-input{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    overflow: hidden;
}

.info-edit-form-input >>> .van-cell{
    padding: 0;
    height: 100%;
}

.info-edit-form-input >>> .van-cell .van-field__body{
    height: 100%;
}

.info-edit-form-input >>> .van-cell .van-field__control{
    padding: 0 10px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    color: #606266;
}
.info-edit-form-input >>> .van-cell .van-field__clear{
    right: 10px;
}

.info-edit-form-input.info-edit-form-star{
    border-color: #F56C6C;
}

.grade_pick >>> .van-picker-column__item{
    /*height: unset;*/
    /*min-height: 44px;*/
}
.grade_pick >>>.van-ellipsis {
    width: 100%;
    overflow: unset;
    white-space: pre-wrap;
}
>>>.grade-label{
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    width: 100%;
    justify-content: center;
}
>>>.grade-title{
    color: #606266;
    width: 45px;
    flex-shrink: 0;
}

>>>.grade-msg{
    color: rgb(132, 146, 166);
    word-wrap: break-word;
    word-break: break-all;
    flex: 1 1;
}

>>>.grade-msg span{
    color: rgb(255, 0, 0);
}
.info-box-edit-star-tip{
    padding: 15px 10px;
    font-weight: 400;
    font-size: 13px;
    color: #FF8522;
    line-height: 18px;
    word-wrap: break-word;
    word-break: break-all;
    background: #FFF9F4;
    margin-bottom: 15px;
}
.info-edit-form-sel{
    margin-top: 12px;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
}

.info-edit-form-sel >>> .van-radio {
    margin-right: 20px;
}

.info-edit-form-sel >>> .van-radio__label {
    font-weight: 400;
    font-size: 13px;
    color: #606266;
    line-height: 18px;
    margin-left: 3px;
}

.info-edit-form-sel >>> .van-checkbox {
    padding: 9px 14px;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    margin-bottom: 15px;
    box-sizing: border-box;
}
.info-edit-form-sel >>> .star-reason-active{
    background: #F5F5FC;
    border: 1px solid #F5F5FC;
}
.info-edit-form-sel >>> .van-checkbox__label{
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
}

.info-edit-form-sel >>> .star-reason-active .van-checkbox__label{
    color: #419EFF;
}

.info-edit-form-checkbox >>> .van-cell{
    height: 100px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    margin: 16px 0;
}
.info-edit-form-checkbox >>> .van-cell::after{
    border: none;
}
.info-edit-form-checkbox >>> .van-cell .van-field__body{
    height: 100%;
}
.info-edit-form-checkbox >>> .van-cell .van-field__body .van-field__control{
    height: 100%;
}

.info-edit-form-textarea >>> .van-cell .van-field__body{
    height: unset!important;
}


.info-edit-form-phone{
    display: flex;
}

.phone-country{
    width: 60px;
    flex-shrink: 0;
}

.phone-country >>> .van-dropdown-menu__bar{
    height: 100%;
}

.phone-country >>> .van-dropdown-menu__title{
    padding-left: 0;
}

.phone-country >>>  .van-ellipsis{
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    line-height: 20px;
}

.phone-country-item{
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
}

.phone-country-item-num{
    font-size: 14px;
    color: #606266;
    line-height: 34px;
}
.phone-country-item-name{
    color: rgb(132, 146, 166);
    font-size: 13px;
    line-height: 34px;
}

.contact-info-box{
    box-shadow: 0px 2px 4px 0px rgba(211,211,211,0.4);
    border-radius: 16px;
    border: 1px solid #F3F5F9;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 15px;
}

.contact-info-box:last-child{
    margin-bottom: 0;
}

.contact-info-type{
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;

}

.contact-info-type-name{
    font-weight: 400;
    color: #6F7379;
}
.contact-info-type-text{
    font-weight: 600;
    color: #272727;
}

.contact-info-box-title{
    font-weight: 600;
    font-size: 16px;
    color: #272727;
    line-height: 22px;
    margin-bottom: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-info-phone-list{

}

.contact-info-phone-item{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.contact-info-phone-item:last-child{
    margin-bottom: 0;
}

.contact-info-phone-item>*{
    flex-shrink: 0;
}

.contact-info-phone-item-left{
    display: flex;
    flex: 1 1;
    width: 100%;
    align-items: center;
}
.contact-info-phone-item-left2{
    width: calc(100% - 28px)!important;
}
.contact-info-phone-item-left>*{
    flex-shrink: 0;
}
.contact-info-phone-item-left .contact-info-phone-name,.contact-info-phone-item-left .contact-info-phone-value{
    font-weight: 400;
    font-size: 13px;
    color: #606266;
    line-height: 18px;
}
.contact-info-phone-num{
    color: #6F7379;
    min-width: 57px;
}

.contact-info-phone-num.contact-info-phone-num2{
    width: 97px;
}



.contact-info-phone-value{
    color: #272727;
    margin-right: 10px;
}

.contact-info-phone-name{
    color: #272727;
    flex: 1 1;
    /*display: -webkit-box;*/
    /*-webkit-box-orient: vertical;*/
    /*-webkit-line-clamp: 1;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.contact-info-phone-name2{
    font-weight: 400;
    font-size: 13px;
    color: #606266;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
}


.contact-info-phone-call{
    width: 16px;
    height: 16px;
    margin-left: 12px;
}
.contact-info-phone-call img{
    width: 100%;
}

.phone-call-disable{
    opacity: 0.4;
}

.add-info{
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    font-size: 18px;
    color: #419EFF;
}

.contact-info-add-item-label{
    font-weight: 600;
    font-size: 13px;
    color: #606266;
    line-height: 18px;
    margin-bottom: 8px;
}
.contact-info-add-item-value{
    font-weight: 400;
    font-size: 13px;
    color: #606266;
    line-height: 18px;
    margin: 10px 0 16px;
}

.contact-info-add-item-edit{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.contact-info-add-list-item:last-child .contact-info-add-item-edit{
    margin-bottom: 0;
}
.info-add-item-name{
    width: 80px;
    margin-right: 8px;
    flex-shrink: 0;
}

.info-add-item-del{
    font-weight: 400;
    font-size: 14px;
    color: #F56C6C;
    line-height: 20px;
    margin-left: 10px;
    flex-shrink: 0;
}

.main-load-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.main-load{
    width: fit-content;
    height: fit-content;
    /*background-color: #fff;*/
}

.spouse-form-input{
    display: flex;
}

.spouse-btn{
    width: 60px;
    margin-left: 10px;
    flex-shrink: 0;
    height: 40px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border: none;
    background: #409EFF;
    color: #FFFFFF;
}

</style>
