<template>
  <div class="audio-players-container">
    <van-dialog
        v-model:show="dialogVisible"
        :title="title + '-录音播放'"
        show-cancel-button
        @close="closeDialog"
        class="rudio_dialog"
        :show-confirm-button="false"
        :show-cancel-button="false"
        close-on-click-overlay
        style="width: 85%;padding: 15px 15px 0"
    >
      <div class="audio-players">
        <div
            v-for="(audio, index) in audioFiles"
            :key="index"
            class="audio-player"
            :class="{ 'last-player': index === audioFiles.length - 1 }"
        >
          <div class="waveform-container" @mousedown="(event) => updateCursor(event, index)">
            <div
                class="waveform"
                :id="'waveform' + index"
                style="width: 100%"
                @click="(event) => handleWaveClick(event, index)"
            ></div>
            <div
                :style="{ top: 0, left: audio.cursorX + 'px' }"
                class="custom-cursor"
            ></div>
          </div>
          <div class="controls">
            <div @click="playPause(index)" style="display: flex">
              <img
                  :src="audio.isPlaying ? play : pause"
                  style="width: 11px; height: 14px; cursor: pointer"
              />
            </div>
            <div class="progress-container">{{ audio.currentTime }} / {{ audio.duration }}</div>
            <input
                class="range-slider"
                :style="getSliderStyle(audio.progress)"
                type="range"
                min="0"
                max="1"
                step="0.01"
                v-model="audio.progress"
                @input="seek(index)"
            />
            <div class="triple-speed" @click="showSpeed = true">{{audio.playbackRate}}x</div>
            <van-action-sheet teleport="body" v-model:show="showSpeed" :actions="actions" @select="setPlaybackRate($event,index)" />
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
export default {
  name: "audio",
  props: ["title","dialogVisible",'src'],
  // props: ["title","dialogVisible",],
  data() {
    return {
      showSpeed:false,
      play: require('@/assets/play.svg'),
      pause: require('@/assets/pause.svg'),
      // src: '', // 替换为你的音频文件路径
      audioUrl: [], // 替换为你的音频文件路径
      updateCursorIntervals:[],
      waveSurfers:[],
      audioFiles:[],
      loadingshow:false,
      actions: [
      { name: '0.5x', value: 0.5 },
      { name: '1x', value: 1.0 },
      { name: '1.5x', value: 1.5 },
      { name: '2x', value: 2.0 }
    ],
      playbackRatesList:[
        { value: 0.5, text: '0.5x' },
        { value: 1, text: '1x' },
        { value: 1.5, text: '1.5x' },
        { value: 2, text: '2x' }
      ],
    }
  },
  created() {
    // this.audioUrl = this.src
  },

  mounted() {
    this.loadingshow=true
    if (Array.isArray(this.src)) {
      this.audioUrl = this.src
    } else {
      this.audioUrl.push({recordUrl:this.src})
    }
    console.log(this.audioUrl)
    this.audioUrl.forEach(item => {
      this.audioFiles.push({ url: item.recordUrl ,
        currentTime :'0:00' ,
        duration: '0:00',
        progress:0 ,
        isPlaying: false,
        playbackRate: 1,
        cursorX: 0,
        // isOpen:'el-icon-arrow-down el-icon--right',
        sliderBackground:'#EDEDED'});
    });
    console.log(this.audioFiles)
    setTimeout(() => {
      this.getAudio()
    }, 0);

  },

  methods: {
    onCancel(){
      this.showSpeed = false
    },
    //创建波形图
    getAudio(){
      this.audioFiles.forEach((audio, index) => {
        // 创建 WaveSurfer 实例
        const wavesurfer = WaveSurfer.create({
          container: `#waveform${index}`,
          /** 波纹高度*/
          height: 100,
          /** 波形拉伸到最大高度 */
          normalize: true,
          /** 波形的颜色 */
          waveColor: '#D8D8D8',
          /** 进度条的颜色 */
          progressColor: '#419EFF',
          /** 播放光标的颜色 */
          cursorColor: '#F9F9FA',
          /** 加载时播放音频 */
          autoplay: false,
          /** 波纹上的是否允许点击 */
          interact: true,
          responsive: true,
        });

        // 加载音频文件
        wavesurfer.load(audio.url);
        this.waveSurfers.push(wavesurfer);
        // 播放音频
        wavesurfer.on('ready', () => {
          audio.duration= this.formatTime(wavesurfer.getDuration());
          this.updateProgress();
          if(this.waveSurfers[this.waveSurfers.length - 1]){
            this.loadingshow=false
          }
        });
        wavesurfer.on('play', this.startCursorUpdate);

        wavesurfer.on('audioprocess', () => {
          this.updateProgress();
        });

        wavesurfer.on('finish', () => {
          audio.isPlaying = false;
        });
      });

    },
    //点击时波形图光标
    updateCursor(event,i) {
      const clickX = event.offsetX; // 获取鼠标相对于波形的 X 坐标
      this.audioFiles[i].cursorX = clickX // 更新光标位置
    },
    //播放时波形图光标
    startCursorUpdate() {
      this.audioFiles.forEach((audio, index) => {
        const wavesurfer = this.waveSurfers[index]; // 确保你有一个 wavesurfer 实例数组
        if (wavesurfer) {
          const intervalId = setInterval(() => {
            const currentTime = wavesurfer.getCurrentTime();
            const duration = wavesurfer.getDuration();
            const waveformWidth = document.querySelector(`#waveform${index}`).clientWidth;
            audio.cursorX = (currentTime / duration) * waveformWidth; // 计算光标位置
          }, 100); // 每100毫秒更新一次
          this.updateCursorIntervals.push(intervalId); // 存储定时器 ID
        }
      });
    },
    //播放和暂停
    playPause(i) {
      const wavesurfer = this.waveSurfers[i]; // 确保你有一个 wavesurfer 实例数组
      if (this.audioFiles[i].isPlaying) {
        wavesurfer.pause();
      } else {
        wavesurfer.play();
      }
      this.audioFiles[i].isPlaying = !this.audioFiles[i].isPlaying;
    },
    //倍速
    setPlaybackRate(item,i) {
      this.showSpeed = false
      console.log(item,i)
      console.log(this.waveSurfers)
      if (this.waveSurfers[i]){
        this.audioFiles[i].playbackRate = item.value;
        this.waveSurfers[i].setPlaybackRate(item.value);
      }

    },
    //倍速下拉选图标
    handleVisibleChange(visible,i){
      this.audioFiles[i].isOpen = visible ? 'el-icon-arrow-up el-icon--right' : 'el-icon-arrow-down el-icon--right'
    },
    //播放时更新进度条
    updateProgress() {
      this.audioFiles.forEach((audio, index) => {
        const wavesurfer = this.waveSurfers[index]; // 确保你有一个 wavesurfer 实例数组
        if (wavesurfer) {
          audio.progress = wavesurfer.getCurrentTime() / wavesurfer.getDuration();
          audio.currentTime = this.formatTime(wavesurfer.getCurrentTime()); // 假设你有一个显示当前时间的地方
        }
      });
    },
    //波形图点击时更新进度条
    handleWaveClick(event,i){
      const wavesurfer = this.waveSurfers[i]; // 确保你有一个 wavesurfer 实例数组
      const waveformWidth =  document.querySelector(`#waveform${i}`).clientWidth
      const clickX = event.offsetX; // 点击位置相对于波形容器的 X 坐标
      const duration = wavesurfer.getDuration(); // 音频总时长
      const clickTime = (clickX / waveformWidth) * duration; // 计算点击对应的时间
      this.audioFiles[i].progress = clickTime / duration
      this.audioFiles[i].currentTime = this.formatTime(clickTime)
      wavesurfer.seekTo(clickTime / duration);
    },
    //播放器点击时更新进度条
    seek(i) {
      const duration = this.waveSurfers[i].getDuration();
      const newTime = this.audioFiles[i].progress * duration;
      const waveformWidth = document.querySelector(`#waveform${i}`).clientWidth;
      this.waveSurfers[i].seekTo(newTime / duration);
      this.audioFiles[i].currentTime = this.formatTime(newTime);
      this.audioFiles[i].cursorX = (newTime / duration) * waveformWidth; // 计算光标位置
    },
    getSliderStyle(progress) {
      // 计算渐变背景
      return {
        background: `linear-gradient(to right, #606266 ${progress * 100}%, #EDEDED ${progress * 100}%)`
      };
    },
    //下载音频
    downloadAudio(){
      const link = document.createElement('a');
      link.href = this.audioUrl;
      link.download = this.title+'.mp3'; // 设置下载文件名
      document.body.appendChild(link); // 将链接添加到文档
      link.click(); // 模拟点击下载
      document.body.removeChild(link); // 下载后移除链接
    },
    //关闭弹窗
    closeDialog(){
      this.updateCursorIntervals.forEach(intervalId => clearInterval(intervalId)); // 清除所有定时器
      this.waveSurfers.forEach(wavesurfer => wavesurfer.pause());
      this.waveSurfers.forEach(wavesurfer => wavesurfer.destroy());
      this.audioUrl = []
      this.updateCursorIntervals = []
      this.audioFiles = []
      this.waveSurfers = []
      this.$emit('cloceAudio', false);
    },
    //音量调节
    setVolume() {
      this.wavesurfer.setVolume(this.volume);
    },
    //时间转换
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
  },
  beforeDestroy() {
    if (this.waveSurfers) {
      this.waveSurfers.forEach(wavesurfer => wavesurfer.destroy());
    }
  }
}
</script>

<style scoped>
.audio-player {
  width: 100%;
  margin-bottom: 20px;
}
.last-player{
  margin-bottom:0;
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress-container {
  margin-left: 10px;
  white-space: nowrap;
  font-size: 14px;
  color: #606266;
}
.download{
  border-radius: 14px;
  background: #419EFF;
  padding: 7px;
  cursor: pointer;
}

input[type="range"] {
  margin-left: 10px;
  width: 100%;
  cursor: pointer;
}
.range-slider {
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  border-radius: 3px;
  height: 6px;
}

/*.range-slider::-webkit-slider-thumb {*/
/*  -webkit-appearance: none;*/
/*  height: 0;*/
/*  width: 0;*/
/*}*/

/*.range-slider::-moz-range-thumb {*/
/*  height: 0;*/
/*  width: 0;*/
/*}*/
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* 去掉默认样式 */
  appearance: none; /* 去掉默认样式 */
  width: 12px; /* 拖动手柄宽度 */
  height: 12px; /* 拖动手柄高度 */
  border-radius: 50%; /* 圆形拖动手柄 */
  background: transparent; /* 默认透明 */
  transition: background 0.2s; /* 动画效果 */
  visibility: hidden; /* 隐藏手柄 */
}
.range-slider:hover::-webkit-slider-thumb {
  background: #606266; /* 悬浮时手柄颜色 */
  visibility: visible; /* 悬浮时显示手柄 */
}

.range-slider::-moz-range-thumb {
  width: 12px; /* 拖动手柄宽度 */
  height: 12px; /* 拖动手柄高度 */
  border-radius: 50%; /* 圆形拖动手柄 */
  background: transparent; /* 默认透明 */
  transition: background 0.2s; /* 动画效果 */
  visibility: hidden; /* 隐藏手柄 */
}

.range-slider:hover::-moz-range-thumb {
  background: #606266; /* 悬浮时手柄颜色 */
  visibility: visible; /* 悬浮时显示手柄 */
}



.selected {
  color: #409EFF;
}
.img {
  display: block;
  margin: 0;
  padding: 0;
}

.volume-control {
  margin-top: 10px;
}

.waveform {
  background-color: #F9F9FA; /* 设置背景色 */
  cursor: pointer;
  height: 100px; /* 根据需要设置波形高度 */
}
.waveform-container {
  position: relative; /* 相对定位，用于绝对定位光标 */
}


.custom-cursor {
  position: absolute;
  width: 1px; /* 调整光标宽度 */
  height: 100%; /* 高度与波形一致 */
  background: linear-gradient(180deg, #F5F5FD 0%, #C0C4CC 50%, #F5F5FD 100%);
  pointer-events: none; /* 避免光标阻止鼠标事件 */
  z-index: 999;
}

.triple-speed{
  width: 50px;
  font-size: 14px;
  text-align: center;
}
.audio-players-container >>> .van-dialog__header{
  padding:0 0 15px 0;
  text-align: left;
}
</style>
